export const ProductList = [
    {
        "filename": "greenstarimg1.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg2.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg3.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg4.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg5.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg6.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg7.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination",
            "island"
        ]
    },
    {
        "filename": "greenstarimg8.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination",
            "island",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg9.png",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg10.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg11.png",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "colorcoordination",
            "appliancecabinet"
        ]
    },
    {
        "filename": "greenstarimg12.png",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg13.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "woodgrain",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg14.png",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "colorcoordination",
            "appliancecabinet"
        ]
    },
    {
        "filename": "greenstarimg15.png",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination",
            "appliancecabinet",
            "island",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg16.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "appliancecabinet",
            "gloss",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg17.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg18.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "appliancecabinet",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg19.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg20.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "woodgrain",
            "colorcoordination",
            "island",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg21.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "appliancecabinet",
            "colorcoordination",
            "island"
        ]
    },
    {
        "filename": "greenstarimg22.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "appliancecabinet",
            "island"
        ]
    },
    {
        "filename": "greenstarimg23.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg24.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "appliancecabinet",
            "island",
            "woodgrain"
        ]
    },
    {
        "filename": "greenstarimg25.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "gloss",
            "appliancecabinet",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg26.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "appliancecabinet",
            "island",
            "matte",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg27.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "rustic",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg28.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "appliancecabinet",
            "island",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg29.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "colorcoordination",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg30.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte"
        ]
    },
    {
        "filename": "greenstarimg31.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte"
        ]
    },
    {
        "filename": "greenstarimg32.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte"
        ]
    },
    {
        "filename": "greenstarimg33.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte"
        ]
    },
    {
        "filename": "greenstarimg34.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg35.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg36.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "woodgrain",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg37.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "island"
        ]
    },
    {
        "filename": "greenstarimg38.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "island"
        ]
    },
    {
        "filename": "greenstarimg39.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "island"
        ]
    },
    {
        "filename": "greenstarimg40.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg41.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "woodgrain"
        ]
    },
    {
        "filename": "greenstarimg42.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "island",
            "gloss",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg43.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg44.jpg",
        "tags": [
            "kitchencabinet",
            "island"
        ]
    },
    {
        "filename": "greenstarimg45.jpg",
        "tags": [
            "kitchencabinet",
            "island"
        ]
    },
    {
        "filename": "greenstarimg46.jpg",
        "tags": [
            "kitchencabinet",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg47.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "island"
        ]
    },
    {
        "filename": "greenstarimg48.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "island"
        ]
    },
    {
        "filename": "greenstarimg49.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "appliancecabinet"
        ]
    },
    {
        "filename": "greenstarimg50.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg51.png",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg52.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg53.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg54.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss",
            "colorcoordination"
        ]
    },
    {
        "//": "Recurred and deleted",
        "filename": "greenstarimg55.jpg",
        "tags": [
        ]
    },
    {
        "filename": "greenstarimg56.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "island",
            "appliancecabinet",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg57.jpg",
        "tags": [
            "kitchencabinet",
            "island",
            "appliancecabinet",
            "gloss",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg58.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg59.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg60.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg61.jpg",
        "tags": [
            "kitchencabinet",
            "island"
        ]
    },
    {
        "filename": "greenstarimg62.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "island",
            "woodgrain"
        ]
    },
    {
        "filename": "greenstarimg63.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "island",
            "woodgrain"
        ]
    },
    {
        "filename": "greenstarimg64.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte"
        ]
    },
    {
        "//": "Recurred and deleted",
        "filename": "greenstarimg65.jpg",
        "tags": []
    },
    {
        "filename": "greenstarimg66.jpg",
        "tags": [
            "systemcabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg67.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg68.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg69.jpg",
        "tags": [
            "systemcabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg70.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg71.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg72.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg73.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg74.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg75.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg76.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg77.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg78.png",
        "tags": [
            "systemcabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg79.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg80.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg81.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg82.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg83.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg84.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg85.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg86.png",
        "tags": [
            "systemcabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg87.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg88.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg89.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg90.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg91.png",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg92.jpg",
        "tags": [
            "systemcabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg93.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "appliancecabinet"
        ]
    },
    {
        "filename": "greenstarimg94.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "appliancecabinet",
            "gloss",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg95.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "gloss",
            "glassdoor"
        ]
    },
    {
        "filename": "greenstarimg96.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg97.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "colorcoordination",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg98.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "gloss",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg99.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg100.png",
        "tags": [
            "kitchencabinet",
            "ushape",
            "gloss"
        ]
    },
    {
        "filename": "greenstarimg101.png",
        "tags": [
            "kitchencabinet",
            "ushape",
            "appliancecabinet",
            "island",
            "rustic"
        ]
    },
    {
        "filename": "greenstarimg102.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "gloss",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg103.jpg",
        "tags": [
            "kitchencabinet",
            "appliancecabinet"
        ]
    },
    {
        "filename": "greenstarimg104.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg105.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "island",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg106.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte"
        ]
    },
    {
        "filename": "greenstarimg107.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg108.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "colorcoordination",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg109.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg110.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg111.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg112.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "matte",
            "appliancecabinet",
            "island"
        ]
    },
    {
        "filename": "greenstarimg113.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg114.jpg",
        "tags": [
            "kitchencabinet",
            "linear",
            "rustic",
            "appliancecabinet",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg115.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "matte",
            "glassdoor"
        ]
    },
    {
        "filename": "greenstarimg116.jpg",
        "tags": [
            "kitchencabinet",
            "ushape",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg117.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg118.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "rustic",
            "colorcoordination",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg119.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "rustic",
            "popular"
        ]
    },
    {
        "filename": "greenstarimg120.jpg",
        "tags": [
            "kitchencabinet",
            "lshape",
            "appliancecabinet",
            "island",
            "matte",
            "colorcoordination"
        ]
    },
    {
        "filename": "greenstarimg121.jpg",
        "tags": [
            "bathroom"
        ]
    },
    {
        "filename": "greenstarimg122.jpg",
        "tags": [
            "systemcabinet"
        ]
    },
    {
        "filename": "greenstarimg123.jpg",
        "tags": [
            "kitchencabinet",
            "matte",
            "colorcoordination"
        ]
    }
]
