
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';

import Marquee from "react-fast-marquee";


import mailImg from '../../assets/mail.png'
import maleImg from '../../assets/male.png'
import femaleImg from '../../assets/female.png'
import linefillImg from '../../assets/linefill.png'
import phoneImg from '../../assets/phone.png'
import mapImg from '../../assets/map.png'
import qrcodeImg from '../../assets/qrcode.png'



import { Link } from "react-router-dom";


import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
  useSearchParams

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Dialog from '@mui/material/Dialog';





const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const contactList = [
    {
      img: phoneImg,
      content: "市話：03-5555881"
    },
    {
      img: maleImg,
      content: "陳先生：0978-316-369"
    },
    {
      img: femaleImg,
      content: "莊小姐：0989-316-289"
    },
    {
      img: linefillImg,
      content: <div>LineID：<a className="text-main underline" href="https://page.line.me/352gxyil?oat__id=2338131&openQrModal=true" target="_blank">@greenstar (或Line主頁搜尋"綠星廚具")</a></div>
    },
    // {
    //   img: mailImg,
    //   content: "E-mail：harry861122@gmail.com"
    // },
  ]


  const handleScroll = event => {
    //console.log('scrollTop: ', event.currentTarget.scrollTop);
    //console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    //console.log('offsetHeight: ', event.currentTarget.scrollHeight);



  };




  useEffect(() => {


  }, []);

  return <div className=" overflow-hidden py-20"   >



    <div className="relative appWidth mb-8 px-[20px] ">
      <h1 className="text-5xl font-semibold mb-20 text-center">聯絡我們</h1>
      <div className="relative w-full mb-20 lg:mb-40">
        <div className="shadow-md bg-white w-full lg:w-2/3 rounded-lg border-2 border-solid border-main p-[30px] sm:p-[50px]">
          {
            contactList.map((i, index) => <div key={i} className="flex items-center text-black91 text-h5 font-bold my-6">
              <img className="relative object-contain w-[28px] h-[28px] mr-3" src={i.img} />
              {i.content}
            </div>
            )
          }

        </div>

        <div className="shadow-md mt-6 lg:mt-0 w-full lg:w-2/5 lg:absolute right-0 top-1/2  lg:-translate-y-1/2 rounded-lg bg-main p-[30px] sm:p-[50px]">

          <div className="text-white text-h5 ">
            <p className="mb-4 font-bold  text-h2">營業時間</p>
            <p className="mb-1">平日：9:00~18:00 (12:00~13:30午休)</p>
            <p className="mb-1">六日：14:00~17:00</p>
            <p className="mb-1">特殊休假日請查看 GOOGLE MAP</p>
          </div>
        </div>

      </div>

      <div className="relative mb-20">
        <div className="shadow-md w-full lg:w-1/2  rounded-lg border-2 border-solid border-main overflow-hidden">
          <img className="relative object-contain w-full" src={mapImg} />

        </div>

        <div className="shadow-md mt-6 lg:mt-0 w-full lg:w-2/5 lg:absolute right-0 top-1/2  lg:-translate-y-1/2 rounded-lg bg-main p-[30px] sm:p-[50px]">

          <div className="text-white text-h5 ">
            <p className="mb-4 font-bold  text-h2">門市地址</p>
            <p className="mb-1">新竹縣竹北市白地街276巷6-3號</p>
            <p className="mb-4">新竹縣竹北市白地街276巷8-3號</p>

            <a href="https://g.page/GreenStarTw?share" target="_blank" >
              <div className="bg-white text-gray-500 font-bold  pl-4 pr-6 py-2  rounded-lg flex gap-2 items-center mr-auto">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 48 48">
                  <path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path>
                </svg>
                導航至Google地圖

              </div>
            </a>
          </div>
        </div>

      </div>

      <div className="relative">
        <p className="text-[36px] font-semibold mb-8 text-center">LINE QR CODE</p>
        <img className="relative object-contain w-full mx-auto max-w-[250px] mb-6" src={qrcodeImg} />

        <div className="flex">
          <a className="underline text-[#06C755] text-center mx-auto text-h4" href="https://page.line.me/352gxyil?oat__id=2338131&openQrModal=true" target="_blank">點此加入綠星</a>
        </div>
      </div>


    </div>
  </div>

}

export default Home