import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import "./style.css";


// import required modules
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";


export default function App({ list, selTypeIndex }) {

  const [banner, setBanner] = useState(list)
  const navigate = useNavigate()


  useEffect(() => {



  }, []);

  const pagination = {
    "clickable": true,

  }

  return (
    <>
      <Swiper
        //slidesPerView={2.05}
        breakpoints={{
          // when window width is >= 100px
          100: {
            slidesPerView: 1.2,
          },
          // when window width is >= 968px
          668: {
            slidesPerView: 2.2,
          },
          968: {
            slidesPerView: 2.8,
          },
        }}
        //centeredSlides={true}
        loop={true}
        spaceBetween={20}
        grabCursor={true}
        //pagination={pagination}
        autoplay={true}
        modules={[Autoplay]}
        //modules={[Pagination, Autoplay]}
        className="mySwiper autoplay"
      >

        {
          banner.map((i, index) => <SwiperSlide key={index} onClick={() => selTypeIndex(index)} className=" bg-white cursor-pointer relative mr-4 sm:mr-12 my-6 p-4  shadow-[2px_2px_10px_rgba(0,0,0,0.08)]  border-2 border-solid hover:border-main border-transparent rounded-lg  ">
            <div className="grid grid-cols-12 gap-2 items-center">
              <div className=" col-span-7    rounded-lg aspect-[4/3] overflow-hidden">
                <img src={i.img} className="w-full h-full object-cover " />
              </div>

              <div className=" w-full col-span-5  text-main font-semibold text-xl ">
                {i.title}
              </div>
            </div>
          </SwiperSlide>)
        }

        {/* <SwiperSlide  className="rounded-md "><div className="bg-main rounded w-full px-[20px] py-[50px]">
            <p className="text-main2 text-left font-bold text-[22px] mb-[40px]">進一步了解我們的方案與服務</p>
            <div className="grid  gap-x-[10px] gap-y-[10px]">
              {
                props.subServiceList.map(i =>
                  <button onClick={()=>navigate(i.link)} className="flex items-center cursor-pointer">
                    <p className="text-white font-bold text-lg mr-3">{i.title}</p>
                   
                  </button>
                )}
            </div>

          </div></SwiperSlide> */}



      </Swiper>


    </>
  );
}
