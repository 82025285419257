
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';


import { Link } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { orderProduct } from "../../global/OrderProduct"
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
  useSearchParams

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";



import Dialog from '@mui/material/Dialog';
import removeImg from '../../assets/remove.png'
import { useGlobalFunc } from "../../global/constants";





const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { setLoading, updateMe } = useGlobalFunc()

  const [open, setOpen] = useState(false);

  const [orderList, setOrderList] = useState([{ typeIndex: 0, itemIndex: 0, unit: "" },]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isLogin, setIsLogin] = useState(false);

  const [account, setAccount] = useState('');
  const [pw, setPw] = useState('');



  const [name, setName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [lineId, setLineId] = useState('');
  const [message, setMessage] = useState('');


  const handleScroll = event => {
    //console.log('scrollTop: ', event.currentTarget.scrollTop);
    //console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    //console.log('offsetHeight: ', event.currentTarget.scrollHeight);

  };



  const handleChangeType = (event, selIndex) => {
    const temp = orderList.map((item, index) => {
      if (index == selIndex) {
        return { ...item, typeIndex: Number(event.target.value), itemIndex: 0 };
      }
      return item
    });

    setOrderList(temp)
  };

  const handleChangeItem = (event, selIndex) => {
    const temp = orderList.map((item, index) => {
      if (index == selIndex) {
        return { ...item, itemIndex: Number(event.target.value) };
      }
      return item
    });

    setOrderList(temp)
  };
  const handleChangeUnit = (event, selIndex) => {
    const temp = orderList.map((item, index) => {
      if (index == selIndex) {
        return { ...item, unit: Number(event.target.value) };
      }
      return item
    });

    setOrderList(temp)
  };

  const handleAddOrder = () => {
    setOrderList([...orderList, { typeIndex: 0, itemIndex: 0, unit: "" }]);
  };

  const handleRemoveOrder = (index) => {

    const temp = [...orderList];

    temp.splice(index, 1)
    console.log(temp)

    setOrderList(temp);

  };


  function login() {


    setLoading(true)

    let json = {
      "account_id": account,
      "account_pw": pw,

    }

    const api_url = process.env.REACT_APP_API_URL + `/api/v1/admin/login`

    //console.log(api_url)
    axios.post(api_url, json)
      .then(response => {


        console.log(response.data.login_token)

        localStorage.setItem('userToken', 'admin006')
        setIsLogin(true)

        setAccount('')
        setPw('')
        
        setLoading(false)

      })
      .catch(error => {
        alert('帳號或密碼錯誤，登入失敗')
        console.log(error)
        setLoading(false)
      });


  }
  function logout() {
    localStorage.removeItem('userToken')
    setIsLogin(false)
  }


  function sendOrder() {

    if (name == "" || phoneNum == "" || address == "") {
      alert('名稱，電話號碼，地址為必填。')
      return
    }

    if (email != "" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert('請輸入正確的電子郵件。')
      return
    }

    setLoading(true)
    let tempList = []

    for (let i = 0; i < orderList.length; i = i + 1) {
      let temp = {
        "name": orderProduct[orderList[i].typeIndex].item[orderList[i].itemIndex].title,
        "type": orderProduct[orderList[i].typeIndex].type,
        "index": orderProduct[orderList[i].typeIndex].item[orderList[i].itemIndex].itemIndex,
        "quantity": Number(orderList[i].unit) > 0 ? Number(orderList[i].unit) : 0

      }

      tempList.push(temp)

    }


    let json = {
      "name": name,
      "phone": phoneNum,
      "address": address,
      //"line": lineId,
      //"email": email,
      "product_description": {
        "data": tempList
      }
    }
    if (lineId != "") {
      json = { ...json, line: lineId }
    }
    if (email != "") {
      json = { ...json, email: email }
    }

    const api_url = process.env.REACT_APP_API_URL + `/api/v1/order`

    //console.log(api_url)
    axios.post(api_url, json)
      .then(response => {
        console.log(response.data)
        console.log(response.status)
        if (response.status == 200) {
          alert('已經收到您的提交，我們快盡快回覆報價，謝謝。')
          setName('')
          setPhoneNum('')
          setAddress('')
          setEmail('')
          setLineId('')
          setMessage('')
          setLoading(false)
        }
      })
      .catch(error => {
        alert(error)
        console.log(error)
        setLoading(false)
      });


  }



  useEffect(() => {
    if (localStorage.getItem('userToken')) {
      setIsLogin(true)
    }


  }, []);

  return <div className=" overflow-hidden py-10"   >

    {
      !isLogin ?
        <div className="appWidth max-w-[400px] px-[20px] mb-8">
          <p className="text-5xl font-semibold mb-20 text-center">管理員專區</p>

          <div className="grid grid-cols-1  gap-5 ">
            <div>
              <p className="font-semibold mb-2">帳號 <span className="text-main">*</span></p>
              <input value={account} onChange={(e) => setAccount(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="帳號" />
            </div>

            <div>
              <p className="font-semibold mb-2">密碼 <span className="text-main">*</span></p>
              <input value={pw} onChange={(e) => setPw(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" type="password" placeholder="密碼" />
            </div>

            <div className="w-full mt-4">
              <button onClick={() => login()} className=" bg-main w-full p-3 rounded-full text-white font-semibold">登入</button>
            </div>
          </div>
        </div>
        :
        <div className="appWidth px-[20px] flex justify-end ">

          <button onClick={() => logout()} className=" bg-main w-20 p-3 rounded-full text-white font-semibold">登出</button>

        </div>
    }

    {
      isLogin &&
      <div>
        {orderProduct.length != 0 ?
          <div className="appWidth px-[20px] mb-8">
            <p className="text-5xl font-semibold mb-20 text-center">挑選產品</p>
            <div className="mb-5 bg-main p-3 flex justify-around rounded-lg text-white font-semibold">
              <div>類別</div>
              <div>品名</div>
              <div>數量</div>
            </div>


            <div className=" bg-white flex flex-col divide-y-2 divide-main divide-opacity-0 mb-20 border-main border-solid border-2 border-opacity-70  rounded-lg text-white ">
              {
                orderList.map((i, index) => <div key={index} className=" px-2 py-4 text-black">
                  <p className="pl-1 text-sm mb-1 font-extrabold">品項 {index + 1}</p>

                  <div className=" lg:flex gap-2 lg:gap-5 justify-between items-center text-black91">
                    <div className="w-full flex flex-col md:flex-row gap-2 lg:gap-4">

                      <div className="w-full md:w-52 flex-none border-[#D1ECA5] border-solid border-2  rounded-lg">

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="w-full h-[50px]"
                          value={i.typeIndex}

                          onChange={(e) => handleChangeType(e, index)}
                        >
                          {
                            orderProduct.map((i, typeIndex) =>
                              <MenuItem key={typeIndex} value={typeIndex}>{i.type}</MenuItem>)
                          }
                        </Select>
                      </div>
                      <div className="w-full border-[#D1ECA5] border-solid border-2  rounded-lg">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="w-full h-[50px]"
                          value={i.itemIndex}
                          onChange={(e) => handleChangeItem(e, index)}
                        >
                          {
                            orderProduct[i.typeIndex].item.map((i, itemIndex) =>
                              <MenuItem key={itemIndex} value={itemIndex}>{i.title}</MenuItem>)
                          }
                        </Select>
                      </div>

                      <div className="flex-none flex items-center gap-0">
                        <div className=" relative flex justify-between items-center w-full md:w-[100px] border-[#D1ECA5] border-solid border-2 rounded-lg ">
                          <input onChange={(e) => handleChangeUnit(e, index)} value={i.unit} type="number" className="w-full bg-transparent py-3 pl-1 pr-9" />
                          <p className="text-sm absolute right-1 top-1/2 -translate-y-1/2 pointer-events-none">{i.itemIndex < orderProduct[i.typeIndex].item.length ? orderProduct[i.typeIndex].item[i.itemIndex].unit : ""}</p>
                        </div>
                        <img onClick={() => handleRemoveOrder(index)} className="ml-2 flex-none cursor-pointer w-8 h-8 my-auto" src={removeImg} />
                      </div>
                    </div>
                  </div>
                </div>)
              }



              <div className="flex justify-center my-4">
                <button onClick={() => handleAddOrder()} className="px-4 py-2 bg-main rounded-lg text-base font-semibold">新增</button>
              </div>
            </div>

          </div>
          : ""
        }

        <div className="appWidth px-[20px] mb-8">
          <p className="text-5xl font-semibold mb-20 text-center">基本資料</p>

          <div className="grid grid-cols-2 gap-5 ">
            <div>
              <p className="font-semibold mb-2">名稱 <span className="text-main">*</span></p>
              <input value={name} onChange={(e) => setName(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="名稱" />
            </div>

            <div>
              <p className="font-semibold mb-2">電話號碼 <span className="text-main">*</span></p>
              <input value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="電話號碼" />
            </div>


            <div className="col-span-2 ">
              <p className="font-semibold mb-2">地址 <span className="text-main">*</span></p>
              <input value={address} onChange={(e) => setAddress(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="地址" />
            </div>

            <div>
              <p className="font-semibold mb-2">電子郵件</p>
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="電子郵件" />
            </div>

            <div>
              <p className="font-semibold mb-2">LineID</p>
              <input value={lineId} onChange={(e) => setLineId(e.target.value)} className="w-full border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="LineID" />
            </div>

            <div className="w-full col-span-2">
              <p className="font-semibold mb-2">您需要哪方面的協助？</p>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} style={{ "resize": "none" }} className="w-full h-36 border-[#D1ECA5] border-solid border-2 p-3 rounded-lg" placeholder="我想詢問供應情形和費用，請針對下列事項提供協助..." />
            </div>


            <div className="w-full col-span-2">
              <button onClick={() => sendOrder()} className=" bg-main w-full p-3 rounded-full text-white font-semibold">提交</button>


            </div>

          </div>
        </div>

      </div>
    }


  </div >

}

export default Home