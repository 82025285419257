
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';

import Marquee from "react-fast-marquee";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import radixcloseImg from '../../assets/radixclose.png'


import menuDownArrowImg from '../../assets/menudownarrow.png';


import Checkbox from '@mui/material/Checkbox';

import { ProductList } from "../../global/Product";
import { ProductFilter } from "../../global/ProductFilter";



import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import Dialog from '@mui/material/Dialog';

import logoImg from '../../assets/logo.png'

import Button from '../../compontents/Button'


import { Link } from "react-router-dom";


import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
  useSearchParams

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";







const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClickOpen = (filename) => {
    setSelectedImage(filename);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function countProductsByTag( tagToFind) {
    const countTags = ProductList.reduce((count, product) => {
      if (product.tags.includes(tagToFind)) {
        return count + 1;
      }
      return count;
    }, 0);
  
    return countTags;
  }


  const handleMenulist = (selIndex) => {
    const temp = menulist.map((item, index) => {
      if (index === selIndex) {
        return { ...item, open: !item.open };
      }
      return item;
    });
    setMenulist(temp);
  };

  function removeSelTagListItem(mainIndex, subIndex) {
    const newItems = selTagList;
    for (let i = 0; i < newItems.length; i = i + 1) {
      if (newItems[i].mainIndex == mainIndex && newItems[i].subIndex == subIndex) {
        console.log()
        newItems.splice(i, 1); // 從複製的陣列中刪除指定元素
      }
    }
    console.log(newItems)
    setSelTagList(newItems); // 更新狀態以反映更改
  }

  const handleMenulistCheckbox = (mainIndex, subIndex) => {
    console.log(mainIndex, subIndex)
    const temp = menulist.map((item, index) => {
      if (index === mainIndex) {
        let tempSub = item.sub
        console.log(tempSub)
        tempSub[subIndex].sel = !tempSub[subIndex].sel
        if (tempSub[subIndex].sel) {
          setSelTagList([...selTagList,
          {
            mainIndex: mainIndex, subIndex: subIndex, title: tempSub[subIndex].title, value: tempSub[subIndex].value
          }
          ])
        }
        else {
          removeSelTagListItem(mainIndex, subIndex)
        }

        return { ...item, sub: tempSub };
      }
      return item;
    });
    setMenulist(temp);
  };

  const [selTagList, setSelTagList] = useState([])

  const [menulist, setMenulist] = useState(ProductFilter)


  const handleScroll = event => {
    //console.log('scrollTop: ', event.currentTarget.scrollTop);
    //console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    //console.log('offsetHeight: ', event.currentTarget.scrollHeight);



  };




  useEffect(() => {

    handleMenulistCheckbox(3,2)

  }, []);

  return <div className=" overflow-hidden py-10"   >

    <div className=" px-[20px] appWidth flex lg:flex-row flex-col  gap-10 mb-8">
      <div className="relative flex-none w-full lg:w-[305px]  ">


        {
          menulist.map((i, index) => <div key={i.title}>
            <ListItemButton onClick={() => handleMenulist(index)} sx={{ px: 0 }}>
              <ListItemText primary={menulist[index].title} primaryTypographyProps={
                {
                  color: menulist[index].open ? '#12171E' : '#12171E',
                  fontWeight: 600,
                }} />
              <img className={`w-[19px] h-[19px] object-contain ${menulist[index].open ? " rotate-180" : ""}`} src={menuDownArrowImg} />
            </ListItemButton>
            <Collapse in={menulist[index].open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {
                  menulist[index].sub.map((i, subIndex) =>
                    <ListItemButton onClick={() => handleMenulistCheckbox(index, subIndex)} key={i.path} sx={{ pl: 0 }} >
                      <div className="pointer-events-none">
                        <Checkbox
                          checked={i.sel}
                          {...label}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 }, '&.Mui-checked': {
                              color: '#1ac768',
                            },
                          }}
                        />
                      </div>
                      <ListItemText primary={i.title + ` (${countProductsByTag(i.value)})`} primaryTypographyProps={
                        {
                          color: '#12171E',
                          fontWeight: 400,
                        }} />
                    </ListItemButton>
                  )
                }
              </List>
            </Collapse>
          </div>)
        }
      </div>

      <div className=" flex-auto w-full text-black91 ">
        <div className="flex flex-wrap gap-2 mb-8">
        
          {
            selTagList.map(i => <div key={i.title} onClick={() => handleMenulistCheckbox(i.mainIndex, i.subIndex)} className=" cursor-pointer flex pl-4 pr-3 py-2 items-center bg-[#ECECEC] text-black91 rounded-full">
              <p>{i.title}</p>
              <img className="ml-3 w-6  object-contain" src={radixcloseImg} />
            </div>)
          }
        </div>


        <div className="relative w-full grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-5">
          {
            selTagList.length === 0 ? ProductList.map(i => (
              <div key={i.filename} onClick={() => handleClickOpen(i.filename)} className="cursor-pointer w-full">

                <div className="w-full rounded-lg  aspect-[1/1] overflow-hidden">
                  <div className="hover:scale-105 anim">
                    <LazyLoadImage
                      style={{ "width": "100%", "aspectRatio": "1/1", "objectFit": "cover" }}
                      alt={i.filename}

                      src={require(`../../assets/productImg/${i.filename}`)} // use normal <img> attributes as props
                      PlaceholderSrc={logoImg}
                    />
                  </div>

                  {/* <img className="hidden w-full rounded-lg object-cover aspect-[1/1]" src={require(`../../assets/productImg/${i.filename}`)} /> */}
                </div>
                <p className="text-main mt-4 hidden">{"00123456"}</p>
                <p className="hidden text-black92 font-semibold mt-2">{i.filename}</p>
              </div>
            ))
              :
              // ProductList.filter((arr) => arr.tags.some((itemB) => selTagList.some((itemA) => itemA.value === itemB))).map(i =>
                ProductList.filter((arr) => selTagList.every((itemA) => arr.tags.includes(itemA.value))).map(i => 
                <div key={i.filename} onClick={() => handleClickOpen(i.filename)} className="cursor-pointer w-full">

                  <div className="w-full rounded-lg  aspect-[1/1] overflow-hidden">
                    <div className="hover:scale-105 anim">
                      <LazyLoadImage
                        style={{ "width": "100%", "aspectRatio": "1/1", "objectFit": "cover" }}
                        alt={i.filename}

                        src={require(`../../assets/productImg/${i.filename}`)} // use normal <img> attributes as props
                        PlaceholderSrc={logoImg}
                      />
                    </div>

                    {/* <img className="hidden w-full rounded-lg object-cover aspect-[1/1]" src={require(`../../assets/productImg/${i.filename}`)} /> */}
                  </div>
                  <p className="text-main mt-4 hidden">{"00123456"}</p>
                  <p className="hidden text-black92 font-semibold mt-2">{i.filename}</p>
                </div>)
          }

        </div>

      </div>

    </div>

    {
      selectedImage != '' ?
        <Dialog open={open} onClose={handleClose}>
          <img src={require(`../../assets/productImg/${selectedImage}`)} alt={selectedImage} style={{ maxWidth: '100%', maxHeight: '80vh' }} />
        </Dialog>
        : ""
    }
  </div>

}

export default Home