
import { useDispatch } from 'react-redux';


import { useTranslation } from "react-i18next";



export const useGlobalFunc = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const setLoading = async (status) => {
    if (status == true) {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: true }
      });
    }
    else {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: false }
      });
    }

  };



  return {  setLoading,};

};



