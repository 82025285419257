import { menuData } from "../global/OrderProduct"
import {useState} from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll,scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const Button = (props) =>{
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const colorStyle = () => {
      if (props.type == 1){
        return "buttonStyle1"
      }
      else if (props.type == 2){
        return "buttonStyle2"
      }
      else if (props.type == 3){
        return "buttonStyle3"
      }
      else if (props.type == 4){
        return "buttonStyle4"
      }
      else if (props.type == 5){
        return "buttonStyle5"
      }
      else if (props.type == 6){
        return "buttonStyle6"
      }
    }
   
    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW'){
            i18n.changeLanguage('en');
        }
        else{
            i18n.changeLanguage('zh-TW');
        }
 
      }; 

    

    return <><button className={`min-w-40 flex font-semibold items-center rounded-full relative text-[18px] leading-[24px] pl-[26px] pr-[20px] py-[12px] ${colorStyle()} border-solid border-[3px] `} onClick={()=>props.onClick()}>
         {props.title} 
         <div className={`border-[3px] border-solid dot w-3 h-3 ml-8 rounded-full`}></div>
    </button>
    </>

}
export default Button