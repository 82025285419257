export const orderProduct = [
    {
        "type": "廚具 - 下櫃",
        "item": [
            {
                "title": "下櫃白鐵桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 0
            },
            {
                "title": "下櫃白鐵桶身+五面霧面結烤門板",
                "unit": "公分",
                "itemIndex": 1
            },
            {
                "title": "下櫃白鐵桶身+五面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 2
            },
            {
                "title": "下櫃白鐵桶身+五面珍珠硬化門板",
                "unit": "公分",
                "itemIndex": 3
            },
            {
                "title": "下櫃白鐵桶身+五面急凍霧化門板",
                "unit": "公分",
                "itemIndex": 4
            },
            {
                "title": "下櫃白鐵桶身+六面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 5
            },
            {
                "title": "下櫃白鐵桶身+六面霧面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 6
            },
            {
                "title": "下櫃白鐵桶身+六面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 7
            },
            {
                "title": "下櫃白鐵桶身+六面珍珠硬化門板",
                "unit": "公分",
                "itemIndex": 8
            },
            {
                "title": "下櫃白鐵桶身+六面雲絲笤門板",
                "unit": "公分",
                "itemIndex": 9
            },
            {
                "title": "下櫃白鐵桶身+六面翡翠玻璃門板",
                "unit": "公分",
                "itemIndex": 10
            },
            {
                "title": "下櫃白鐵桶身+五面高壓門板(貼皮)",
                "unit": "公分",
                "itemIndex": 11
            },
            {
                "title": "下櫃白鐵桶身+五面高壓藝術門板(貼皮)",
                "unit": "公分",
                "itemIndex": 12
            },
            {
                "title": "下櫃白鐵桶身+六面平板陶瓷烤漆門板",
                "unit": "公分",
                "itemIndex": 13
            },
            {
                "title": "下櫃白鐵桶身+六面瓷釉烤漆門板",
                "unit": "公分",
                "itemIndex": 14
            },
            {
                "title": "下櫃白鐵桶身+卡多麗熱修復門板",
                "unit": "公分",
                "itemIndex": 15
            },
            {
                "title": "下櫃白鐵桶身+六面FENIX熱修門板 單面",
                "unit": "公分",
                "itemIndex": 16
            },
            {
                "title": "下櫃白鐵桶身+六面FENIX熱修門板 雙面",
                "unit": "公分",
                "itemIndex": 17
            },
            {
                "title": "下櫃白鐵桶身+六面高壓陶瓷烤漆門板",
                "unit": "公分",
                "itemIndex": 18
            },
            {
                "title": "下櫃白鐵桶身+實木烤漆門板",
                "unit": "公分",
                "itemIndex": 19
            },
            {
                "title": "下櫃木心板桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 20
            },
            {
                "title": "下櫃木心板桶身+五面霧面結烤門板",
                "unit": "公分",
                "itemIndex": 21
            },
            {
                "title": "下櫃木心板桶身+五面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 22
            },
            {
                "title": "浴櫃發泡板桶身+六面結晶鋼烤門板 含白鐵緩衝",
                "unit": "公分",
                "itemIndex": 23
            },
            {
                "title": "下櫃美耐板桶身(六面)+六面美耐門板",
                "unit": "公分",
                "itemIndex": 24
            },
            {
                "title": "下櫃白鐵桶身+五面美耐門板",
                "unit": "公分",
                "itemIndex": 25
            },
            {
                "title": "中島白鐵桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 26
            },
            {
                "title": "中島木心板桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 27
            },
            {
                "title": "中島木心板桶身+五面霧面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 28
            },
            {
                "title": "中島木心板桶身+五面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 29
            },
            {
                "title": "中島木心板桶身+五五面高壓成型門板(貼皮)",
                "unit": "公分",
                "itemIndex": 30
            },
            {
                "title": "中島木心板桶身+六面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 31
            },
            {
                "title": "平台木心板桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 32
            },
            {
                "title": "平台木心板桶身+五面霧面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 33
            },
            {
                "title": "平台木心板桶身+五面霧烤硬化門板",
                "unit": "公分",
                "itemIndex": 34
            },
            {
                "title": "水槽白鐵桶身外加",
                "unit": "公分",
                "itemIndex": 35
            },
            {
                "title": "F1木心板桶身外加",
                "unit": "公分",
                "itemIndex": 36
            },
            {
                "title": "完成面加高至90公分",
                "unit": "公分",
                "itemIndex": 37
            }
        ]
    },
    {
        "type": "廚具 - 吊櫃",
        "item": [
            {
                "title": "吊櫃木心板桶身+五面結晶鋼烤門板 高70內",
                "unit": "公分",
                "itemIndex": 38
            },
            {
                "title": "吊櫃木心板桶身+五面霧面結烤門板 高70內",
                "unit": "公分",
                "itemIndex": 39
            },
            {
                "title": "吊櫃木心板桶身+五面霧面硬化門板 高70內",
                "unit": "公分",
                "itemIndex": 40
            },
            {
                "title": "吊櫃木心板桶身+五面霧面耐刮門板 高70內",
                "unit": "公分",
                "itemIndex": 41
            },
            {
                "title": "吊櫃木心板桶身+六面結晶鋼烤門板 高70內",
                "unit": "公分",
                "itemIndex": 42
            },
            {
                "title": "吊櫃木心板桶身+六面雲絲笤門板 高70內",
                "unit": "公分",
                "itemIndex": 43
            },
            {
                "title": "吊櫃木心板桶身+六面霧面硬化門板 高70內",
                "unit": "公分",
                "itemIndex": 44
            },
            {
                "title": "吊櫃木心板桶身+六面平板陶瓷烤漆門板 高70內",
                "unit": "公分",
                "itemIndex": 45
            },
            {
                "title": "吊櫃木心板桶身+六面瓷釉烤漆門板 高70內",
                "unit": "公分",
                "itemIndex": 46
            },
            {
                "title": "吊櫃木心板桶身+卡多麗熱修復門板 高70內",
                "unit": "公分",
                "itemIndex": 47
            },
            {
                "title": "吊櫃木心板桶身+六面FENIX熱修復門板 高70內 單",
                "unit": "公分",
                "itemIndex": 48
            },
            {
                "title": "吊櫃木心板桶身+六面FENIX熱修復門板 高70內 雙",
                "unit": "公分",
                "itemIndex": 49
            },
            {
                "title": "吊櫃木心板桶身+六面高壓陶瓷烤漆門板 高70內",
                "unit": "公分",
                "itemIndex": 50
            },
            {
                "title": "吊櫃木心板桶身+五面高壓門板(貼皮) 高70內",
                "unit": "公分",
                "itemIndex": 51
            },
            {
                "title": "吊櫃木心板桶身+五面高壓藝術門板 高70內",
                "unit": "公分",
                "itemIndex": 52
            },
            {
                "title": "吊櫃木心板桶身+五面美耐門板 高70內",
                "unit": "公分",
                "itemIndex": 53
            },
            {
                "title": "吊櫃木心板桶身升級F1",
                "unit": "公分",
                "itemIndex": 54
            },
            {
                "title": "吊櫃木心板桶身換雙層白鐵桶",
                "unit": "公分",
                "itemIndex": 55
            },
            {
                "title": "鏡箱發泡板桶身+發泡板門板 高70",
                "unit": "公分",
                "itemIndex": 56
            },
            {
                "title": "系統牆釘層板 厚2.5公分",
                "unit": "式",
                "itemIndex": 57
            },
            {
                "title": "美耐板牆釘層板 厚2.5公分",
                "unit": "式",
                "itemIndex": 58
            },
            {
                "title": "吊櫃木心板桶身開放櫃",
                "unit": "式",
                "itemIndex": 59
            },
            {
                "title": "吊櫃美耐板(六面)桶身開放櫃",
                "unit": "公分",
                "itemIndex": 60
            },
            {
                "title": "吊櫃系統板桶身開放櫃",
                "unit": "公分",
                "itemIndex": 61
            }
        ]
    },
    {
        "type": "廚具 - 高櫃",
        "item": [
            {
                "title": "電器櫃木心板桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 62
            },
            {
                "title": "電器櫃木心板桶身+五面霧面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 63
            },
            {
                "title": "電器櫃木心板桶身+六面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 64
            },
            {
                "title": "電器櫃木心板桶身+五面霧面硬化門板",
                "unit": "公分",
                "itemIndex": 65
            },
            {
                "title": "電器櫃木心板桶身+五面高壓門板(貼皮)",
                "unit": "公分",
                "itemIndex": 66
            },
            {
                "title": "電器櫃木心板桶身+高壓藝術門板(貼皮)",
                "unit": "公分",
                "itemIndex": 67
            },
            {
                "title": "電器櫃美耐桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 68
            },
            {
                "title": "隱藏緩衝式托盤",
                "unit": "式",
                "itemIndex": 69
            },
            {
                "title": "緩衝式托盤",
                "unit": "式",
                "itemIndex": 70
            },
            {
                "title": "高櫃木心板桶身+五面結晶鋼烤門板 高220內",
                "unit": "公分",
                "itemIndex": 71
            },
            {
                "title": "高櫃木心板桶身+五面霧面結烤門板 高220內",
                "unit": "公分",
                "itemIndex": 72
            },
            {
                "title": "高櫃木心板桶身+五面霧面硬化門板 高220內",
                "unit": "公分",
                "itemIndex": 73
            },
            {
                "title": "高櫃木心板桶身+六面FENIX熱修門板 單 高220內",
                "unit": "公分",
                "itemIndex": 74
            },
            {
                "title": "高櫃木心板桶身+五面高壓門板(貼皮) 高220內",
                "unit": "公分",
                "itemIndex": 75
            },
            {
                "title": "高櫃美耐板桶身(六面)+六面美耐門板 高220內",
                "unit": "公分",
                "itemIndex": 76
            },
            {
                "title": "中身櫃木心板桶身+五面結晶鋼烤門板+玻璃旋轉五金",
                "unit": "組",
                "itemIndex": 77
            },
            {
                "title": "中身櫃木心板桶身+五面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 78
            },
            {
                "title": "玻璃旋轉中身櫃五金(30/40公分)",
                "unit": "組",
                "itemIndex": 79
            },
            {
                "title": "JAS碳鋼烤漆中身櫃五金HF1040X1",
                "unit": "組",
                "itemIndex": 80
            }
        ]
    },
    {
        "type": "廚具 - 冰箱櫃",
        "item": [
            {
                "title": "冰箱櫃木心板桶身+五面結晶鋼烤門板 深60內",
                "unit": "公分",
                "itemIndex": 81
            },
            {
                "title": "冰箱櫃木心板桶身+五面霧面結烤門板 深60內",
                "unit": "公分",
                "itemIndex": 82
            },
            {
                "title": "冰箱櫃木心板桶身+五面霧烤硬化門板 深60內",
                "unit": "公分",
                "itemIndex": 83
            },
            {
                "title": "冰箱櫃木心板桶身+五面高壓門板(貼皮)",
                "unit": "公分",
                "itemIndex": 84
            },
            {
                "title": "冰箱櫃木心板桶身+五面高壓藝術門板 深60內",
                "unit": "公分",
                "itemIndex": 85
            },
            {
                "title": "冰箱櫃木心板桶身+六面FENIX熱修復門板 單 深60內",
                "unit": "公分",
                "itemIndex": 86
            }
        ]
    },
    {
        "type": "廚具 - 檯面",
        "item": [
            {
                "title": "石英石檯面(含4公分背牆) 豪華系列 深60內",
                "unit": "公分",
                "itemIndex": 87
            },
            {
                "title": "洲際石英石檯面(含4公分背牆) 深60內",
                "unit": "公分",
                "itemIndex": 88
            },
            {
                "title": "矽鋼石檯面(含4公分背牆) 深60公分 2系列",
                "unit": "公分",
                "itemIndex": 89
            },
            {
                "title": "闊石檯面(含4公分背牆) 深60內",
                "unit": "公分",
                "itemIndex": 90
            },
            {
                "title": "帝雉石檯面(含4公分背牆) 深60內",
                "unit": "公分",
                "itemIndex": 91
            },
            {
                "title": "耐麗石檯面(含4公分背牆) 深60內 對紋另計",
                "unit": "公分",
                "itemIndex": 92
            },
            {
                "title": "賽麗石檯面(含4公分背牆)  1、2系列 厚1.2 深60內",
                "unit": "公分",
                "itemIndex": 93
            },
            {
                "title": "愷薩金石檯面(含4公分背牆)深60內 經典系列",
                "unit": "公分",
                "itemIndex": 94
            },
            {
                "title": "愷薩金石檯面(含4公分背牆)深60內 超自然/清水模系列",
                "unit": "公分",
                "itemIndex": 95
            },
            {
                "title": "愷薩金石檯面(含4公分背牆)深60內 雕花系列",
                "unit": "公分",
                "itemIndex": 96
            },
            {
                "title": "瑪格麗特石英石檯面(含4公分背牆)深60內 E05Vulcano",
                "unit": "公分",
                "itemIndex": 97
            },
            {
                "title": "日本BMC晶石檯面(含5公分背牆) 深60內 max270",
                "unit": "公分",
                "itemIndex": 98
            },
            {
                "title": "LG/樂天人造石檯面(含止水線、4公分背牆) 深60內",
                "unit": "公分",
                "itemIndex": 99
            },
            {
                "title": "白鐵檯面(304含止水線、背牆) 厚1.0mm+內雙層木心板",
                "unit": "公分",
                "itemIndex": 100
            },
            {
                "title": "白鐵珍珠壓花檯面(含前止水、背牆)1.0+雙層木心板",
                "unit": "公分",
                "itemIndex": 101
            },
            {
                "title": "美耐檯面 厚3公分 深60公分內",
                "unit": "公分",
                "itemIndex": 102
            },
            {
                "title": "檯面加深至公分",
                "unit": "公分",
                "itemIndex": 103
            },
            {
                "title": "中島石英石檯面 豪華系列 寬90內 倒包5公分",
                "unit": "公分",
                "itemIndex": 104
            },
            {
                "title": "中島人造石檯面 寬80公分 倒包5公分",
                "unit": "公分",
                "itemIndex": 105
            },
            {
                "title": "中島白鐵檯面 寬90公分 倒包5公分",
                "unit": "公分",
                "itemIndex": 106
            },
            {
                "title": "中島石英石雙面落地下包 寬90內",
                "unit": "片",
                "itemIndex": 107
            },
            {
                "title": "中島落地下包斜接工資",
                "unit": "式",
                "itemIndex": 108
            }
        ]
    },
    {
        "type": "廚具 - 孔工",
        "item": [
            {
                "title": "石英石挖爐孔*1+水槽下嵌*1工資(非平接)",
                "unit": "式",
                "itemIndex": 109
            },
            {
                "title": "人造石挖爐孔*1+水槽下嵌工資*1(非平接)",
                "unit": "式",
                "itemIndex": 110
            },
            {
                "title": "日本BMC晶石挖爐孔*1+水槽下嵌工資*1(非平接)",
                "unit": "式",
                "itemIndex": 111
            },
            {
                "title": "白鐵檯面挖爐孔*1+水槽平接工資*1",
                "unit": "式",
                "itemIndex": 112
            },
            {
                "title": "耐麗石挖爐孔*1+水槽下嵌工資*1(非平接)",
                "unit": "式",
                "itemIndex": 113
            },
            {
                "title": "美耐檯面挖爐孔*1+水槽上嵌工資*1",
                "unit": "式",
                "itemIndex": 114
            },
            {
                "title": "花崗岩水槽上嵌平接工資",
                "unit": "式",
                "itemIndex": 115
            },
            {
                "title": "花崗岩水槽下嵌平接工資",
                "unit": "式",
                "itemIndex": 116
            },
            {
                "title": "IH爐平接工資(雙口)",
                "unit": "式",
                "itemIndex": 117
            },
            {
                "title": "IH爐平接工資(單口)",
                "unit": "式",
                "itemIndex": 118
            },
            {
                "title": "石英/人造石導小圓角(假厚4公分、半徑5公分1/4圓)",
                "unit": "式",
                "itemIndex": 119
            },
            {
                "title": "石英/人造石導R角(半徑1公分)",
                "unit": "式",
                "itemIndex": 120
            }
        ]
    },
    {
        "type": "廚具 - 水槽",
        "item": [
            {
                "title": "水槽自備自裝",
                "unit": "式",
                "itemIndex": 121
            },
            {
                "title": "水槽代送代裝工資(無保固 不含排水管以外配件)",
                "unit": "式",
                "itemIndex": 122
            },
            {
                "title": "尚奕SE-760PA-1 76公分白鐵水槽  厚1.0",
                "unit": "組",
                "itemIndex": 123
            },
            {
                "title": "尚奕SE-2790 74公分白鐵手工水槽  厚1.2",
                "unit": "組",
                "itemIndex": 124
            },
            {
                "title": "內徑79公分白鐵壓花水槽 海灣型 5配件 厚1.0",
                "unit": "組",
                "itemIndex": 125
            },
            {
                "title": "內徑77公分白鐵大水槽 海灣型 厚1.0 塑膠防蟑提籠",
                "unit": "組",
                "itemIndex": 126
            },
            {
                "title": "內徑76公分白鐵大水槽 厚0.7 塑膠防蟑提籠",
                "unit": "組",
                "itemIndex": 127
            },
            {
                "title": "內徑KL-101W 65公分水槽 白鐵防蟑提籠粘掛藍 厚1.0",
                "unit": "組",
                "itemIndex": 128
            },
            {
                "title": "內徑65公分白鐵水槽 白鐵防蟑提籠 海灣型 厚1.0",
                "unit": "組",
                "itemIndex": 129
            },
            {
                "title": "內徑67公分白鐵水槽 塑膠防蟑提籠 海灣型 厚1.0",
                "unit": "組",
                "itemIndex": 130
            },
            {
                "title": "內徑65公分白鐵水槽 一般提籠 海灣型 厚0.7",
                "unit": "組",
                "itemIndex": 131
            },
            {
                "title": "內徑56公分白鐵水槽 一般提籠",
                "unit": "組",
                "itemIndex": 132
            },
            {
                "title": "內徑60公分白鐵水槽 一般提籠",
                "unit": "組",
                "itemIndex": 133
            },
            {
                "title": "內徑92公分白鐵水槽KL-8198",
                "unit": "組",
                "itemIndex": 134
            },
            {
                "title": "內徑82公分白鐵R角手工方槽 厚1.2釐米 防蟑提籠",
                "unit": "組",
                "itemIndex": 135
            },
            {
                "title": "內徑72公分白鐵R角手工方槽 厚1.2釐米 防蟑提籠",
                "unit": "組",
                "itemIndex": 136
            },
            {
                "title": "內徑66公分白鐵R角手工方槽 厚1.2釐米 防蟑提籠",
                "unit": "組",
                "itemIndex": 137
            },
            {
                "title": "內徑55公分白鐵R角手工方槽 厚1.2釐米 防蟑提籠",
                "unit": "組",
                "itemIndex": 138
            },
            {
                "title": "內徑47公分白鐵R角手工方槽 厚1.2釐米 防蟑提籠",
                "unit": "組",
                "itemIndex": 139
            },
            {
                "title": "白鐵水槽KL-601 內徑74公分",
                "unit": "組",
                "itemIndex": 140
            },
            {
                "title": "白鐵壓花水槽KL-605L 內徑72公分 左平台",
                "unit": "組",
                "itemIndex": 141
            },
            {
                "title": "白鐵水槽KL-502 內徑74公分",
                "unit": "組",
                "itemIndex": 142
            },
            {
                "title": "白鐵水槽KL-501 內徑67公分",
                "unit": "組",
                "itemIndex": 143
            },
            {
                "title": "白鐵水槽KL-508 內徑40公分",
                "unit": "組",
                "itemIndex": 144
            },
            {
                "title": "白鐵水槽KL-304 內徑30公分 直放",
                "unit": "組",
                "itemIndex": 145
            },
            {
                "title": "白鐵水槽KL-303 內徑42公分",
                "unit": "組",
                "itemIndex": 146
            },
            {
                "title": "白鐵水槽KL-302 內徑51公分",
                "unit": "組",
                "itemIndex": 147
            },
            {
                "title": "白鐵水槽KL-003 內徑65公分",
                "unit": "組",
                "itemIndex": 148
            },
            {
                "title": "白鐵水槽KL-005 內徑86公分 +通用半邊籃+後置中提",
                "unit": "組",
                "itemIndex": 149
            },
            {
                "title": "白鐵水槽KL-8379 內徑74公分 厚1.2 含木砧板",
                "unit": "組",
                "itemIndex": 150
            },
            {
                "title": "白鐵圓形吧檯槽KL-006B+後置ST中提籠",
                "unit": "組",
                "itemIndex": 151
            },
            {
                "title": "96公分白鐵雙槽KL-205 內徑大56小27",
                "unit": "組",
                "itemIndex": 152
            },
            {
                "title": "86公分白鐵雙槽KL-203 內徑大45小27",
                "unit": "組",
                "itemIndex": 153
            },
            {
                "title": "白鐵水槽KL-004 內徑74公分 雙層歐化單槽 亮面",
                "unit": "組",
                "itemIndex": 154
            },
            {
                "title": "內徑67公分白鐵水槽 KL-003 ",
                "unit": "組",
                "itemIndex": 155
            },
            {
                "title": "白鐵水槽KL-002 內徑74公分 雙層歐化單槽 毛絲面",
                "unit": "組",
                "itemIndex": 156
            },
            {
                "title": "KL-102 西德圓弧(大堤)",
                "unit": "組",
                "itemIndex": 157
            },
            {
                "title": "KL-104崁入式雙層大橢圓槽 內徑60公分",
                "unit": "組",
                "itemIndex": 158
            },
            {
                "title": "KL-105L左海灣單槽",
                "unit": "組",
                "itemIndex": 159
            },
            {
                "title": "KL-105BL左海灣單槽",
                "unit": "組",
                "itemIndex": 160
            },
            {
                "title": "KL-601W不鏽鋼水槽砧板掛籃(珍珠壓花)",
                "unit": "組",
                "itemIndex": 161
            },
            {
                "title": "內徑76公分白鐵大水槽 海灣型KL-168N 白鐵提籠",
                "unit": "組",
                "itemIndex": 162
            },
            {
                "title": "內徑76公分白鐵大水槽 海灣型KL-168N 白鐵提籠+砧板掛籃",
                "unit": "組",
                "itemIndex": 163
            },
            {
                "title": "內徑76公分珍珠壓花白鐵大水槽 海灣型 白鐵提籠",
                "unit": "組",
                "itemIndex": 164
            },
            {
                "title": "內徑76公分白鐵水槽 白鐵防蟑提籠 海灣型 厚1.0",
                "unit": "組",
                "itemIndex": 165
            },
            {
                "title": "內徑73.5公分白鐵水槽KL-166",
                "unit": "組",
                "itemIndex": 166
            },
            {
                "title": "內徑68公分白鐵四方水槽KL-167(左掛)",
                "unit": "組",
                "itemIndex": 167
            },
            {
                "title": "內徑78.5公分白鐵水槽KL-172",
                "unit": "組",
                "itemIndex": 168
            },
            {
                "title": "小R角手工白鐵水槽KL9776 大提 含砧板、托盤",
                "unit": "組",
                "itemIndex": 169
            },
            {
                "title": "小R角手工白鐵水槽KL9487",
                "unit": "組",
                "itemIndex": 170
            },
            {
                "title": "小R角手工白鐵水槽KL9477",
                "unit": "組",
                "itemIndex": 171
            },
            {
                "title": "小R角手工白鐵水槽KL9474(中提)",
                "unit": "組",
                "itemIndex": 172
            },
            {
                "title": "內徑63公分小R角手工白鐵水槽KL9467(大提) ",
                "unit": "組",
                "itemIndex": 173
            },
            {
                "title": "小R角手工白鐵水槽KL9462",
                "unit": "組",
                "itemIndex": 174
            },
            {
                "title": "小R角手工白鐵水槽KL9456",
                "unit": "組",
                "itemIndex": 175
            },
            {
                "title": "小R角手工白鐵水槽KL9449",
                "unit": "組",
                "itemIndex": 176
            },
            {
                "title": "歐式手工方型單槽KL-8475 (大堤)",
                "unit": "組",
                "itemIndex": 177
            },
            {
                "title": "歐式手工方型單槽KL-8450",
                "unit": "組",
                "itemIndex": 178
            },
            {
                "title": "歐式3D手工方型水槽(髮絲紋) KL-8379",
                "unit": "組",
                "itemIndex": 179
            },
            {
                "title": "德國海司寶馬花崗岩水槽HA-660 內徑60公分",
                "unit": "組",
                "itemIndex": 180
            },
            {
                "title": "德國海司寶馬花崗岩水槽HA-760 內徑70公分",
                "unit": "組",
                "itemIndex": 181
            },
            {
                "title": "德國海司寶馬花崗岩水槽HA-860 內徑80公分",
                "unit": "組",
                "itemIndex": 182
            },
            {
                "title": "HAFELE內徑64花崗岩水槽GALAXY N-100L (灰黑白棕)",
                "unit": "組",
                "itemIndex": 183
            },
            {
                "title": "HAFELE內徑77花崗岩水槽MONTANO N-100L (灰黑白棕)",
                "unit": "組",
                "itemIndex": 184
            },
            {
                "title": "內徑70公分雨瀑龍頭水槽組(可下嵌) 白鐵/槍灰",
                "unit": "組",
                "itemIndex": 185
            },
            {
                "title": "BLANCO德國不鏽鋼水槽 內徑80公分 R15 800-IU ",
                "unit": "組",
                "itemIndex": 186
            },
            {
                "title": "BLANCO內徑80公分花崗岩水槽800-U(黑/灰/白)",
                "unit": "組",
                "itemIndex": 187
            },
            {
                "title": "日本LIXIL壓花靜音不鏽鋼水槽A9U(內徑76 檯面深65) ",
                "unit": "組",
                "itemIndex": 188
            },
            {
                "title": "日本原裝進口吉本晶石水槽HS780 ",
                "unit": "組",
                "itemIndex": 189
            },
            {
                "title": "SHIGERU日本進口白鐵水槽SD.J820",
                "unit": "組",
                "itemIndex": 190
            },
            {
                "title": "TOYOURA日本進口白鐵壓花水槽N750BIA-EB-MS",
                "unit": "組",
                "itemIndex": 191
            },
            {
                "title": "JPES7548壓紋+納米陶晶層 內徑71公分",
                "unit": "組",
                "itemIndex": 192
            },
            {
                "title": "JPES8050壓紋+納米陶晶層 內徑76公分",
                "unit": "組",
                "itemIndex": 193
            },
            {
                "title": "雨瀑水槽+龍頭+洗杯器 拉絲 不鏽鋼色",
                "unit": "組",
                "itemIndex": 194
            },
            {
                "title": "雨瀑水槽+龍頭+洗杯器 槍灰色",
                "unit": "組",
                "itemIndex": 195
            },
            {
                "title": "內徑80公分白鐵壓花R角手工方槽 厚2.0 防蟑提籠",
                "unit": "組",
                "itemIndex": 196
            },
            {
                "title": "內徑72公分白鐵壓花R角手工方槽 厚2.0 防蟑提籠",
                "unit": "組",
                "itemIndex": 197
            },
            {
                "title": "內徑60公分白鐵壓花R角手工方槽 厚2.0 防蟑提籠",
                "unit": "組",
                "itemIndex": 198
            },
            {
                "title": "內徑86公分亮面白鐵單槽",
                "unit": "組",
                "itemIndex": 199
            },
            {
                "title": "KL9434水槽(小提)髮絲紋",
                "unit": "組",
                "itemIndex": 200
            },
            {
                "title": "達榮SINK系列亂紋手工方槽60RT(540*420*220)",
                "unit": "組",
                "itemIndex": 201
            }
        ]
    },
    {
        "type": "廚具 - 龍頭",
        "item": [
            {
                "title": "JAS白鐵兩用龍頭 LF無鉛+CNS認證 三年保固",
                "unit": "組",
                "itemIndex": 202
            },
            {
                "title": "JAS白鐵兩用龍頭 LF無鉛+CNS認證 三年保 黑色",
                "unit": "組",
                "itemIndex": 203
            },
            {
                "title": "美國RECO白鐵兩用龍頭 LF無鉛+CNS認證",
                "unit": "組",
                "itemIndex": 204
            },
            {
                "title": "美國RECO 伸縮兩用龍頭 LF無鉛+CNS認證103014-B  ",
                "unit": "組",
                "itemIndex": 205
            },
            {
                "title": "美國RECO VOCES白鐵兩用伸縮龍頭",
                "unit": "組",
                "itemIndex": 206
            },
            {
                "title": "美國RECO兩用伸縮龍頭FEDER霧黑 交叉水流",
                "unit": "組",
                "itemIndex": 207
            },
            {
                "title": "BAOLU白鐵伸縮兩用龍頭 CNS認證",
                "unit": "組",
                "itemIndex": 208
            },
            {
                "title": "BAOLU三用伸縮龍頭 槍灰色 RO-1053 換小頭",
                "unit": "組",
                "itemIndex": 209
            },
            {
                "title": "BAOLU兩用伸縮龍頭 槍灰色 DP-3022 換小頭",
                "unit": "組",
                "itemIndex": 210
            },
            {
                "title": "BAOLU白鐵兩用伸縮龍頭 DP-3023",
                "unit": "組",
                "itemIndex": 211
            },
            {
                "title": "BOSS無鉛廚房伸縮龍頭",
                "unit": "組",
                "itemIndex": 212
            },
            {
                "title": "BOSS白鐵伸縮兩用龍頭 兩年保",
                "unit": "組",
                "itemIndex": 213
            },
            {
                "title": "deluxso不鏽鋼伸縮廚房龍頭(鈦灰)DF7122GM",
                "unit": "組",
                "itemIndex": 214
            },
            {
                "title": "deluxso兩用壁式龍頭DF-7030ST",
                "unit": "組",
                "itemIndex": 215
            },
            {
                "title": "BAOLU BS-4022 白鐵壁式兩用龍頭 CNS認證",
                "unit": "組",
                "itemIndex": 216
            },
            {
                "title": "INAX日本原裝感應式龍頭SF-NA451SU 公司貨",
                "unit": "組",
                "itemIndex": 217
            },
            {
                "title": "合吉歐化兩用龍頭",
                "unit": "組",
                "itemIndex": 218
            },
            {
                "title": "白鐵三用龍頭 LF無鉛+TAF認證",
                "unit": "組",
                "itemIndex": 219
            },
            {
                "title": "合吉歐化三用龍頭",
                "unit": "組",
                "itemIndex": 220
            },
            {
                "title": "合吉歐化伸縮兩用龍頭",
                "unit": "組",
                "itemIndex": 221
            },
            {
                "title": "白鐵伸縮三用龍頭",
                "unit": "組",
                "itemIndex": 222
            },
            {
                "title": "K-BOSS白鐵伸縮三用龍頭 K-LF425RO 槍灰色",
                "unit": "組",
                "itemIndex": 223
            },
            {
                "title": "萬向三用龍頭",
                "unit": "組",
                "itemIndex": 224
            },
            {
                "title": "萬向兩用龍頭 LF無鉛+SGS認證",
                "unit": "組",
                "itemIndex": 225
            },
            {
                "title": "合吉壁式兩用龍頭",
                "unit": "組",
                "itemIndex": 226
            },
            {
                "title": "DAY&DAY SHERLOCK 無鉛伸縮龍頭 EA-072-N",
                "unit": "組",
                "itemIndex": 227
            },
            {
                "title": "DAY&DAY白鐵伸縮兩用龍頭ED-KS4020 日本陶瓷閥芯",
                "unit": "組",
                "itemIndex": 228
            },
            {
                "title": "DAY&DAY白鐵三用龍頭ED-KS83509",
                "unit": "組",
                "itemIndex": 229
            },
            {
                "title": "單冷水龍頭SF-93A",
                "unit": "組",
                "itemIndex": 230
            },
            {
                "title": "單冷水龍頭SF-7116",
                "unit": "組",
                "itemIndex": 231
            },
            {
                "title": "水龍頭安裝工資(不含配件、凡爾及高壓管)",
                "unit": "式",
                "itemIndex": 232
            },
            {
                "title": "水龍頭+凡爾自備自裝(同天可代開檯面/水槽孔)",
                "unit": "式",
                "itemIndex": 233
            },
            {
                "title": "壁式龍頭沿用(無拆裝)",
                "unit": "式",
                "itemIndex": 234
            },
            {
                "title": "不鏽鋼伸縮廚房龍頭(鈦灰) DF-7122GM",
                "unit": "組",
                "itemIndex": 235
            },
            {
                "title": "不銹鋼304廚房四用龍頭LF無鉛認證 伸縮+RO淨水出水",
                "unit": "組",
                "itemIndex": 236
            }
        ]
    },
    {
        "type": "廚具 - 五金",
        "item": [
            {
                "title": "JAS緩衝式薄牆鋁抽 耐重40公斤 三年保固",
                "unit": "組",
                "itemIndex": 237
            },
            {
                "title": "硬式刀叉盤(大)",
                "unit": "組",
                "itemIndex": 238
            },
            {
                "title": "硬式刀叉盤(小)",
                "unit": "組",
                "itemIndex": 239
            },
            {
                "title": "緩衝式托盤",
                "unit": "式",
                "itemIndex": 240
            },
            {
                "title": "隱藏緩衝式托盤",
                "unit": "式",
                "itemIndex": 241
            },
            {
                "title": "JAS薄鋁抽外加止滑墊(含背板、底板貼合)+磁吸底板",
                "unit": "式",
                "itemIndex": 242
            },
            {
                "title": "JAS薄鋁抽外加止滑墊(含背板、底板貼合)",
                "unit": "式",
                "itemIndex": 243
            },
            {
                "title": "JAS磁吸棒",
                "unit": "只",
                "itemIndex": 244
            },
            {
                "title": "JAS座式緩衝小側拉 白鐵款 耐重30公斤 三年保固",
                "unit": "組",
                "itemIndex": 245
            },
            {
                "title": "JAS座式緩衝小側拉 塑盒款 耐重30公斤 三年保固",
                "unit": "組",
                "itemIndex": 246
            },
            {
                "title": "JAS大側拉(FI240JA組合式功能架) 40公斤 三年保",
                "unit": "組",
                "itemIndex": 247
            },
            {
                "title": "JAS緩衝多功能底抽架 45公分 不鏽鋼 40公斤 3年保",
                "unit": "組",
                "itemIndex": 248
            },
            {
                "title": "JAS座式緩衝三邊拉籃(結合門) 白鐵款 40公斤 三年保",
                "unit": "組",
                "itemIndex": 249
            },
            {
                "title": "JAS座式緩衝四邊籃(門開拉籃) 白鐵款 40公斤 三年保",
                "unit": "組",
                "itemIndex": 250
            },
            {
                "title": "JAS三邊碗盤籃 白鐵款線籃KD790J 三年保",
                "unit": "組",
                "itemIndex": 251
            },
            {
                "title": "BLUM緩衝式鋁灰抽 載重30公斤",
                "unit": "組",
                "itemIndex": 252
            },
            {
                "title": "BLUM帝寶金屬抽 載重30公斤 含空運費 不含玻璃",
                "unit": "組",
                "itemIndex": 253
            },
            {
                "title": "BLUM踏板踢腳抽 含踏板 使用30公斤鋁灰抽",
                "unit": "組",
                "itemIndex": 254
            },
            {
                "title": "緩衝式鋁抽 載重20公斤 ",
                "unit": "組",
                "itemIndex": 255
            },
            {
                "title": "緩衝式魔法抽(含內抽及配件)",
                "unit": "組",
                "itemIndex": 256
            },
            {
                "title": "緩衝式三明抽",
                "unit": "組",
                "itemIndex": 257
            },
            {
                "title": "台製緩衝式木抽",
                "unit": "組",
                "itemIndex": 258
            },
            {
                "title": "台製緩衝式造型木抽",
                "unit": "組",
                "itemIndex": 259
            },
            {
                "title": "玻璃旋轉中身櫃五金(30/40公分)",
                "unit": "組",
                "itemIndex": 260
            },
            {
                "title": "KING旋弧轉角小怪物(半展)",
                "unit": "組",
                "itemIndex": 261
            },
            {
                "title": "KING全展轉角小怪物 不鏽鋼線籃",
                "unit": "組",
                "itemIndex": 262
            },
            {
                "title": "JAS緩衝全都露 不鏽鋼線籃 MR215G 三年保",
                "unit": "組",
                "itemIndex": 263
            },
            {
                "title": "JAS緩衝全都露 抗倍特板底 MR115GWAS1 三年保",
                "unit": "組",
                "itemIndex": 264
            },
            {
                "title": "JAS緩衝全都露 強化玻璃底 MR715GGAS1 三年保",
                "unit": "組",
                "itemIndex": 265
            },
            {
                "title": "JAS半邊怪物組AC220G2 不鏽鋼 三年保 寬60 五層",
                "unit": "組",
                "itemIndex": 266
            },
            {
                "title": "JAS延伸式大怪物CA220A 不鏽鋼款 三年保",
                "unit": "組",
                "itemIndex": 267
            },
            {
                "title": "JAS德國HAFELE掀蓋短搖臂",
                "unit": "支",
                "itemIndex": 268
            },
            {
                "title": "垂直上掀門板器",
                "unit": "組",
                "itemIndex": 269
            },
            {
                "title": "KING昇降櫃 線籃款 載重7公斤",
                "unit": "組",
                "itemIndex": 270
            },
            {
                "title": "JAS昇降櫃 線籃款 載重10公斤 三年保",
                "unit": "組",
                "itemIndex": 271
            },
            {
                "title": "JAS昇降櫃 抗倍特板 載重10公斤 三年保",
                "unit": "組",
                "itemIndex": 272
            },
            {
                "title": "JAS緩衝加蓋分類桶架FJ132J 30公分",
                "unit": "組",
                "itemIndex": 273
            },
            {
                "title": "JAS緩衝加蓋分類桶架FJ162J 40公分",
                "unit": "組",
                "itemIndex": 274
            },
            {
                "title": "JAS緩衝資源分類桶架(小)FJ130J 30公分 ",
                "unit": "組",
                "itemIndex": 275
            },
            {
                "title": "玻璃旋轉中身櫃五金(30/40公分)",
                "unit": "組",
                "itemIndex": 276
            },
            {
                "title": "JAS中昇櫃 碳鋼亮鉻抗倍特板底 耐重80公斤 3年保",
                "unit": "組",
                "itemIndex": 277
            },
            {
                "title": "JAS碳鋼烤漆高昇櫃五金HF1030X1 60公斤 3年保",
                "unit": "組",
                "itemIndex": 278
            },
            {
                "title": "JAS緩衝高昇櫃30公分HC131G1 碳鋼亮鉻 80公斤3年保 ",
                "unit": "組",
                "itemIndex": 279
            },
            {
                "title": "ELD-90070電動升降烘碗機90公分",
                "unit": "台",
                "itemIndex": 280
            },
            {
                "title": "日本Panasonic 升降櫃(90cm) F07090",
                "unit": "組",
                "itemIndex": 281
            },
            {
                "title": "日本DAYDO Linear-down Box隱藏式兩用升降櫃 黑",
                "unit": "組",
                "itemIndex": 282
            },
            {
                "title": "日本DAYDO QuickBox 下掀調味料罐架 60公分",
                "unit": "組",
                "itemIndex": 283
            },
            {
                "title": "日本DAYDO Pull down Shelf Z字收納架",
                "unit": "組",
                "itemIndex": 284
            },
            {
                "title": "易利勾",
                "unit": "公分",
                "itemIndex": 285
            },
            {
                "title": "煞車輪子",
                "unit": "只",
                "itemIndex": 286
            },
            {
                "title": "黑色鋁框玻璃門+長虹玻璃 C1A",
                "unit": "片",
                "itemIndex": 287
            }
        ]
    },
    {
        "type": "廚具 - 緩衝鉸鏈",
        "item": [
            {
                "title": "緩衝鉸鏈(110度、三段可調門縫)",
                "unit": "只",
                "itemIndex": 288
            },
            {
                "title": "白鐵緩衝鉸鏈(可調門縫)",
                "unit": "只",
                "itemIndex": 289
            },
            {
                "title": "BLUM背包型緩衝鉸鏈",
                "unit": "只",
                "itemIndex": 290
            },
            {
                "title": "BLUM內建緩衝絞鍊(無蓋、含拍拍手專用絞鍊+拍拍手)",
                "unit": "只",
                "itemIndex": 291
            },
            {
                "title": "BLUM內建緩衝絞鍊(含頭蓋+小金屬Logo蓋)",
                "unit": "只",
                "itemIndex": 292
            }
        ]
    },
    {
        "type": "廚具 - 把手",
        "item": [
            {
                "title": "下櫃門嵌成型把手(鋁)、上櫃無把手(下降門)",
                "unit": "公分",
                "itemIndex": 293
            },
            {
                "title": "門嵌成型把手(鋁)",
                "unit": "公分",
                "itemIndex": 294
            },
            {
                "title": "下櫃門嵌成型把手(黑砂紋)、上櫃無把手(下降門)",
                "unit": "公分",
                "itemIndex": 295
            },
            {
                "title": "門嵌成型把手(黑砂紋)",
                "unit": "公分",
                "itemIndex": 296
            },
            {
                "title": "下櫃櫃內嵌C把手(白/黑)、上櫃無把手(下降門)",
                "unit": "公分",
                "itemIndex": 297
            },
            {
                "title": "櫃內嵌C把手(白/黑)",
                "unit": "公分",
                "itemIndex": 298
            },
            {
                "title": "下櫃外置一般把手(鋁/黑)、上櫃無把手(下降門)",
                "unit": "式",
                "itemIndex": 299
            },
            {
                "title": "外置一般把手(鋁/黑)",
                "unit": "式",
                "itemIndex": 300
            },
            {
                "title": "門上方切斜把手",
                "unit": "公分",
                "itemIndex": 301
            },
            {
                "title": "蛋殼把手",
                "unit": "只",
                "itemIndex": 302
            }
        ]
    },
    {
        "type": "廚具 - 封板",
        "item": [
            {
                "title": "見光面無側封板",
                "unit": "式",
                "itemIndex": 303
            },
            {
                "title": "五面結晶鋼烤側封板(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 304
            },
            {
                "title": "五面霧面結晶鋼烤側封板(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 305
            },
            {
                "title": "五面霧面硬化側封板(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 306
            },
            {
                "title": "六面霧面硬化側封板(深60內)",
                "unit": "片",
                "itemIndex": 307
            },
            {
                "title": "五面高壓成型側封板(貼皮)(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 308
            },
            {
                "title": "五面高壓藝術側封板(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 309
            },
            {
                "title": "六面瓷釉烤漆側封板(深60內)",
                "unit": "片",
                "itemIndex": 310
            },
            {
                "title": "六面FINEX熱修復側封板(深60內) 單面熱修",
                "unit": "片",
                "itemIndex": 311
            },
            {
                "title": "六面平板陶瓷烤漆側封板(深60內)",
                "unit": "片",
                "itemIndex": 312
            },
            {
                "title": "五面結晶鋼烤大側封板(深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 313
            },
            {
                "title": "五面霧面結烤大側封板(高220、深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 314
            },
            {
                "title": "五面霧面硬化大側封板(高220、深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 315
            },
            {
                "title": "五面高壓成型大側封板(高220、深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 316
            },
            {
                "title": "五面高壓藝術側封板(高220、深60內)面向踢腳白色",
                "unit": "片",
                "itemIndex": 317
            },
            {
                "title": "六面結晶鋼烤側封板(高220、深60內)",
                "unit": "片",
                "itemIndex": 318
            },
            {
                "title": "六面結晶鋼烤大側封板(高220、深60內)",
                "unit": "片",
                "itemIndex": 319
            },
            {
                "title": "六面瓷釉烤漆大側封板(深60內)",
                "unit": "片",
                "itemIndex": 320
            },
            {
                "title": "六面FINEX熱修復大側封板(深60內) 單面熱修",
                "unit": "片",
                "itemIndex": 321
            },
            {
                "title": "中島五面結晶鋼烤大背板",
                "unit": "式",
                "itemIndex": 322
            },
            {
                "title": "中島五面霧面結晶鋼烤大背板",
                "unit": "式",
                "itemIndex": 323
            },
            {
                "title": "中島五面高壓成型背板(貼皮)(235)公分",
                "unit": "式",
                "itemIndex": 324
            },
            {
                "title": "中島五面霧面硬化大背板",
                "unit": "式",
                "itemIndex": 325
            },
            {
                "title": "中島六面結晶鋼烤側封板(深80內)",
                "unit": "片",
                "itemIndex": 326
            },
            {
                "title": "中島六面霧面結晶鋼烤側封板(深80內)",
                "unit": "片",
                "itemIndex": 327
            },
            {
                "title": "吧檯造型五面結晶鋼烤側封板",
                "unit": "片",
                "itemIndex": 328
            },
            {
                "title": "洗/烘碗機門片(半580*594/全695*594) 同門版材質",
                "unit": "片",
                "itemIndex": 329
            },
            {
                "title": "霧面結烤洗/烘碗機門片(半嵌580*594/全嵌695*594)",
                "unit": "片",
                "itemIndex": 330
            }
        ]
    },
    {
        "type": "廚具 - 踢腳板",
        "item": [
            {
                "title": "可拆塑膠防水踢腳板(黑/鋁色)+水槽門白鐵刀架、掛勾",
                "unit": "式",
                "itemIndex": 331
            },
            {
                "title": "水槽門不鎖白鐵刀架、掛勾",
                "unit": "式",
                "itemIndex": 332
            },
            {
                "title": "同色踢腳板(無備註則為同門板材質、顏色)",
                "unit": "公分",
                "itemIndex": 333
            }
        ]
    },
    {
        "type": "工資",
        "item": [
            {
                "title": "基本運送安裝工資(非外包)+搬運0樓(計費方式見附件)",
                "unit": "式",
                "itemIndex": 334
            },
            {
                "title": "拆運舊廚(不含拆玻璃、清潔、修補舊廚具覆蓋部分)",
                "unit": "式",
                "itemIndex": 335
            },
            {
                "title": "拆運舊廚+拆玻璃(不含清潔、修補舊廚具覆蓋部分)",
                "unit": "式",
                "itemIndex": 336
            },
            {
                "title": "IH爐基本安裝工資(配電、插座、透氣孔另計) ",
                "unit": "式",
                "itemIndex": 337
            },
            {
                "title": "IH爐散熱開孔(含透氣網 底圓*2+踢腳長*1 黑/鋁/白)",
                "unit": "式",
                "itemIndex": 338
            },
            {
                "title": "烤箱散熱開孔(含透氣網 踢腳長*1 黑/鋁/白)",
                "unit": "式",
                "itemIndex": 339
            },
            {
                "title": "瓦斯爐同天基本安裝工資(含接頭、1米內瓦斯管)",
                "unit": "式",
                "itemIndex": 340
            },
            {
                "title": "瓦斯爐自備自裝(不含接頭、瓦斯管)",
                "unit": "式",
                "itemIndex": 341
            },
            {
                "title": "RO+凡爾自備自裝(同天可代開檯面/水槽孔)",
                "unit": "式",
                "itemIndex": 342
            },
            {
                "title": "RO基本安裝工資(管線、配件另計。無漏水保固)",
                "unit": "式",
                "itemIndex": 343
            },
            {
                "title": "RO+加熱機同天安裝工資(管線、配件另計。無漏水保固)",
                "unit": "式",
                "itemIndex": 344
            },
            {
                "title": "烘碗機同天基本安裝工資(電源延長、插座、門片另計)",
                "unit": "式",
                "itemIndex": 345
            },
            {
                "title": "烘碗機自備自裝(不含電源延長、插座、門片)",
                "unit": "式",
                "itemIndex": 346
            },
            {
                "title": "抽油煙機同天基本安裝工資(含櫃體切合、接短煙管*1)",
                "unit": "式",
                "itemIndex": 347
            },
            {
                "title": "抽油煙機自備自裝(不含電源插座、煙管、廚櫃開孔)",
                "unit": "式",
                "itemIndex": 348
            },
            {
                "title": "中島型抽油煙機基本安裝工資(不含額外鐵罩及鐵架)",
                "unit": "式",
                "itemIndex": 349
            },
            {
                "title": "抽油煙機沿用(無拆裝)",
                "unit": "式",
                "itemIndex": 350
            },
            {
                "title": "洗碗機同天安裝工資(門片、把手、配件另計)",
                "unit": "式",
                "itemIndex": 351
            },
            {
                "title": "洗碗機自備自裝(不含門片、把手、開孔、切踢腳)",
                "unit": "式",
                "itemIndex": 352
            },
            {
                "title": "水槽白鐵桶身底部開孔(圓55/65/75、無蓋板洗碗機用)",
                "unit": "式",
                "itemIndex": 353
            },
            {
                "title": "水槽白鐵桶身底部開孔(方約75*55、有蓋板洗碗機用)",
                "unit": "式",
                "itemIndex": 354
            },
            {
                "title": "爐連烤同天安裝工資(需附上型號/尺寸)",
                "unit": "式",
                "itemIndex": 355
            },
            {
                "title": "電源插座",
                "unit": "式",
                "itemIndex": 356
            },
            {
                "title": "薄木板電梯保護",
                "unit": "式",
                "itemIndex": 357
            },
            {
                "title": "PP板電梯保護",
                "unit": "式",
                "itemIndex": 358
            },
            {
                "title": "油煙管加長",
                "unit": "式",
                "itemIndex": 359
            },
            {
                "title": "7790 7786 T罩管加長",
                "unit": "式",
                "itemIndex": 360
            },
            {
                "title": "自備自裝",
                "unit": "式",
                "itemIndex": 361
            },
            {
                "title": "快速爐+鍋圈",
                "unit": "組",
                "itemIndex": 362
            }
        ]
    },
    {
        "type": "櫻花 (2022.7更新)",
        "item": [
            {
                "title": "櫻花雙炫火三口爐G2932AGB黑玻璃 (左/右)大 天/液",
                "unit": "台",
                "itemIndex": 363
            },
            {
                "title": "櫻花聚熱焱兩口檯面爐G2523YG天/液",
                "unit": "台",
                "itemIndex": 364
            },
            {
                "title": "櫻花瓦斯爐智能雙炫火大面板G2928GB 天/液",
                "unit": "台",
                "itemIndex": 365
            },
            {
                "title": "櫻花智能雙炫火瓦斯爐G2926GB (左/右)空燒 天/液",
                "unit": "台",
                "itemIndex": 366
            },
            {
                "title": "櫻花聚熱焱雙炫火瓦斯爐G2923AG 黑玻璃 天/液",
                "unit": "台",
                "itemIndex": 367
            },
            {
                "title": "櫻花雙口雙炫火瓦斯爐G2922AG 黑玻璃 天/液",
                "unit": "台",
                "itemIndex": 368
            },
            {
                "title": "櫻花雙口雙炫火瓦斯爐G2922BG(奶/粉)玻璃 天/液",
                "unit": "台",
                "itemIndex": 369
            },
            {
                "title": "櫻花雙口雙炫火大面板瓦斯爐G-2921GB 天/液",
                "unit": "台",
                "itemIndex": 370
            },
            {
                "title": "櫻花防空燒三口瓦斯爐G2830KGB 天/液",
                "unit": "台",
                "itemIndex": 371
            },
            {
                "title": "櫻花蓮花爐G2826GB 天/液",
                "unit": "台",
                "itemIndex": 372
            },
            {
                "title": "櫻花防空燒瓦斯爐G2820GB (左/右)空燒 天/液",
                "unit": "台",
                "itemIndex": 373
            },
            {
                "title": "櫻花瓦斯爐G2723GB聚熱焱雙內焰二口檯面爐 天/液",
                "unit": "台",
                "itemIndex": 374
            },
            {
                "title": "櫻花雙內焰二口黑玻璃瓦斯爐G2721GB 天/液",
                "unit": "台",
                "itemIndex": 375
            },
            {
                "title": "櫻花黑玻璃三口瓦斯爐G2633GB (左/右)大 天/液",
                "unit": "台",
                "itemIndex": 376
            },
            {
                "title": "櫻花瓦斯爐雙口不銹鋼面板G-2623S 天/液",
                "unit": "台",
                "itemIndex": 377
            },
            {
                "title": "櫻花瓦斯爐雙口玻璃黑/白G-2623AGB 大面板 天/液",
                "unit": "台",
                "itemIndex": 378
            },
            {
                "title": "櫻花雙口黑玻璃瓦斯爐G2522GB 天/液",
                "unit": "台",
                "itemIndex": 379
            },
            {
                "title": "櫻花雙口白鐵瓦斯爐G2522S 天/液",
                "unit": "台",
                "itemIndex": 380
            },
            {
                "title": "櫻花瓦斯爐G-252KS 天/液",
                "unit": "台",
                "itemIndex": 381
            },
            {
                "title": "櫻花抽油煙機DR-7796SXL",
                "unit": "台",
                "itemIndex": 382
            },
            {
                "title": "櫻花倒T觸控式直流變頻抽油煙機DR-7790 120公分",
                "unit": "台",
                "itemIndex": 383
            },
            {
                "title": "櫻花倒T觸控式直流變頻抽油煙機DR-7790B 90公分",
                "unit": "台",
                "itemIndex": 384
            },
            {
                "title": "櫻花倒T抽油煙機DR-7786B 直流變頻 按鈕式",
                "unit": "台",
                "itemIndex": 385
            },
            {
                "title": "櫻花倒T抽油煙機DR-7786B(80公分) 修飾板換風管罩",
                "unit": "台",
                "itemIndex": 386
            },
            {
                "title": "櫻花倒T抽油煙機R-7765",
                "unit": "台",
                "itemIndex": 387
            },
            {
                "title": "櫻花倒T抽油煙機R-7722BSXL",
                "unit": "台",
                "itemIndex": 388
            },
            {
                "title": "櫻花近吸除油煙機R-7653XL(31公斤承重注意)",
                "unit": "台",
                "itemIndex": 389
            },
            {
                "title": "櫻花近吸除油煙機R-7610XL",
                "unit": "台",
                "itemIndex": 390
            },
            {
                "title": "櫻花近吸除油煙機R-7600XL",
                "unit": "台",
                "itemIndex": 391
            },
            {
                "title": "櫻花近吸半隱藏除油煙機(有升降)R7352",
                "unit": "台",
                "itemIndex": 392
            },
            {
                "title": "櫻花近吸全隱藏除油煙機(有升降)R7351",
                "unit": "台",
                "itemIndex": 393
            },
            {
                "title": "櫻花近吸半隱藏除油煙機(無升降)R7302",
                "unit": "台",
                "itemIndex": 394
            },
            {
                "title": "櫻花近吸全隱藏除油煙機(無升降)R7301",
                "unit": "台",
                "itemIndex": 395
            },
            {
                "title": "櫻花觸控隱藏式直流變頻抽油煙機DR-3592",
                "unit": "台",
                "itemIndex": 396
            },
            {
                "title": "櫻花抽油煙機DR-3590AXL",
                "unit": "台",
                "itemIndex": 397
            },
            {
                "title": "櫻花全隱藏式琺瑯抽油煙機R-3506C",
                "unit": "台",
                "itemIndex": 398
            },
            {
                "title": "櫻花隱藏式抽油煙機R-3500DXL 按鈕式",
                "unit": "台",
                "itemIndex": 399
            },
            {
                "title": "櫻花隱藏式抽油煙機R605 60公分",
                "unit": "台",
                "itemIndex": 400
            },
            {
                "title": "櫻花抽油煙機DR3882B",
                "unit": "台",
                "itemIndex": 401
            },
            {
                "title": "櫻花抽油煙機DR3880B",
                "unit": "台",
                "itemIndex": 402
            },
            {
                "title": "櫻花環吸式抽油煙機R-3751 吸力15 深588",
                "unit": "台",
                "itemIndex": 403
            },
            {
                "title": "櫻花健康型深罩式抽油煙機R-3680XL",
                "unit": "台",
                "itemIndex": 404
            },
            {
                "title": "櫻花斜背式抽油煙機R-3261",
                "unit": "台",
                "itemIndex": 405
            },
            {
                "title": "櫻花斜背式抽油煙機R-3250 白鐵",
                "unit": "台",
                "itemIndex": 406
            },
            {
                "title": "櫻花落地式烘碗機Q7697 分層烘乾 60公分",
                "unit": "台",
                "itemIndex": 407
            },
            {
                "title": "櫻花落地式烘碗機Q7693L 雙門雙層黑玻 W60 H70",
                "unit": "台",
                "itemIndex": 408
            },
            {
                "title": "櫻花落地式烘碗機Q7693 雙門雙層黑玻 W60 特規H68",
                "unit": "台",
                "itemIndex": 409
            },
            {
                "title": "櫻花落地式烘碗機Q7690 單抽雙層黑玻 60公分",
                "unit": "台",
                "itemIndex": 410
            },
            {
                "title": "櫻花落地式烘碗機Q7692",
                "unit": "台",
                "itemIndex": 411
            },
            {
                "title": "櫻花落地式烘碗機Q7650L 薄膜按鍵",
                "unit": "台",
                "itemIndex": 412
            },
            {
                "title": "櫻花落地式烘碗機Q7596B 嵌門",
                "unit": "台",
                "itemIndex": 413
            },
            {
                "title": "櫻花落地式烘碗機Q7595ML 50公分寬",
                "unit": "台",
                "itemIndex": 414
            },
            {
                "title": "櫻花落地式烘碗機Q7592B",
                "unit": "台",
                "itemIndex": 415
            },
            {
                "title": "櫻花吊掛式烘碗機Q7598AXL",
                "unit": "台",
                "itemIndex": 416
            },
            {
                "title": "櫻花吊掛式烘碗機Q7583 黑玻璃",
                "unit": "台",
                "itemIndex": 417
            },
            {
                "title": "櫻花吊掛式烘碗機Q7580 玻璃鏡面液晶 臭氧+紅外線",
                "unit": "台",
                "itemIndex": 418
            },
            {
                "title": "櫻花吊掛式烘碗機Q7565 白 臭氧殺菌",
                "unit": "台",
                "itemIndex": 419
            },
            {
                "title": "櫻花吊掛式烘碗機Q600CW 白 臭氧殺菌 60公分",
                "unit": "台",
                "itemIndex": 420
            },
            {
                "title": "櫻花雙管淨水器P-0780",
                "unit": "台",
                "itemIndex": 421
            },
            {
                "title": "櫻花淨水器P-022",
                "unit": "台",
                "itemIndex": 422
            },
            {
                "title": "櫻花淨水器P-0121",
                "unit": "台",
                "itemIndex": 423
            },
            {
                "title": "櫻花淨水器P-0230 400加侖",
                "unit": "台",
                "itemIndex": 424
            },
            {
                "title": "櫻花淨水器P-0231 600加侖",
                "unit": "台",
                "itemIndex": 425
            },
            {
                "title": "櫻花雙管RO淨水器P-0233A 400加侖 廢水比1:2/3",
                "unit": "台",
                "itemIndex": 426
            },
            {
                "title": "櫻花雙管RO淨水器P-0235 600加侖 專售",
                "unit": "台",
                "itemIndex": 427
            },
            {
                "title": "櫻花廚下熱飲機P-0553A 機械龍頭 需配櫻花RO 含裝",
                "unit": "台",
                "itemIndex": 428
            },
            {
                "title": "櫻花廚下熱飲機P-0563 觸控龍頭 需配櫻花RO 含裝",
                "unit": "台",
                "itemIndex": 429
            },
            {
                "title": "櫻花冷熱飲水機P-0583A 觸控龍頭 內含RO 含裝 停產",
                "unit": "台",
                "itemIndex": 430
            },
            {
                "title": "櫻花冷熱飲水機P-0585 觸控龍頭 內含RO 含裝",
                "unit": "台",
                "itemIndex": 431
            },
            {
                "title": "櫻花電器收納櫃E3621",
                "unit": "台",
                "itemIndex": 432
            },
            {
                "title": "櫻花電器收納櫃E3625",
                "unit": "台",
                "itemIndex": 433
            },
            {
                "title": "櫻花電烤箱E-6672",
                "unit": "台",
                "itemIndex": 434
            },
            {
                "title": "櫻花洗碗機E-7682(半嵌)",
                "unit": "台",
                "itemIndex": 435
            },
            {
                "title": "櫻花半嵌式洗碗機E-7683 自動開門 ",
                "unit": "台",
                "itemIndex": 436
            },
            {
                "title": "櫻花全嵌式洗碗機E-7783 自動開門 ",
                "unit": "台",
                "itemIndex": 437
            },
            {
                "title": "櫻花嵌入式變頻微波烤箱E5650A",
                "unit": "台",
                "itemIndex": 438
            },
            {
                "title": "櫻花單口黑玻瓦斯爐G2112G",
                "unit": "台",
                "itemIndex": 439
            },
            {
                "title": "櫻花單口琺瑯白瓦斯爐G251KE",
                "unit": "台",
                "itemIndex": 440
            },
            {
                "title": "櫻花抽油煙機R-7722SBXL",
                "unit": "台",
                "itemIndex": 441
            },
            {
                "title": "櫻花抽油煙機R-3750BL",
                "unit": "台",
                "itemIndex": 442
            },
            {
                "title": "櫻花全隱藏型除油煙機DR3590A",
                "unit": "台",
                "itemIndex": 443
            },
            {
                "title": "櫻花抽油煙機R-3260S 70公分",
                "unit": "台",
                "itemIndex": 444
            },
            {
                "title": "櫻花抽油煙機R-605 60公分",
                "unit": "台",
                "itemIndex": 445
            },
            {
                "title": "櫻花三口IH爐EG2300G 220V 九段火力 散熱開孔另計",
                "unit": "台",
                "itemIndex": 446
            },
            {
                "title": "櫻花雙口IH爐EG2350G 220V 定時定溫 散熱開孔另計",
                "unit": "台",
                "itemIndex": 447
            },
            {
                "title": "櫻花雙口IH爐EG2331G 220V 定時 散熱開孔另計",
                "unit": "台",
                "itemIndex": 448
            },
            {
                "title": "櫻花雙口IH爐EG2200G 220V 散熱開孔另計",
                "unit": "台",
                "itemIndex": 449
            },
            {
                "title": "櫻花單口IH爐EG2250GB 220V 散熱開孔另計",
                "unit": "台",
                "itemIndex": 450
            },
            {
                "title": "櫻花單口IH爐EG2231G 220V 散熱開孔另計",
                "unit": "台",
                "itemIndex": 451
            },
            {
                "title": "櫻花單口IH爐EG2100GB 220V 散熱開孔另計",
                "unit": "台",
                "itemIndex": 452
            },
            {
                "title": "櫻花單口IH爐EG2120GB 220V 散熱開孔另計",
                "unit": "台",
                "itemIndex": 453
            },
            {
                "title": "櫻花雙口IH爐EG2320GB 220V 停產",
                "unit": "台",
                "itemIndex": 454
            },
            {
                "title": "櫻花雙口IH爐EG2330GB 220V 停產",
                "unit": "台",
                "itemIndex": 455
            },
            {
                "title": "櫻花嵌入爐G-2633GB",
                "unit": "台",
                "itemIndex": 456
            },
            {
                "title": "櫻花台爐G632KS",
                "unit": "台",
                "itemIndex": 457
            },
            {
                "title": "櫻花不鏽鋼雙炫火台爐G5900S",
                "unit": "台",
                "itemIndex": 458
            },
            {
                "title": "櫻花16公升強制排氣熱水器DH-1635F(基本安裝)",
                "unit": "台",
                "itemIndex": 459
            },
            {
                "title": "櫻花16公升美膚沐浴強制排氣DH-1683(基本安裝)",
                "unit": "台",
                "itemIndex": 460
            },
            {
                "title": "櫻花13公升強制排氣熱水器DH-1335E(基本安裝)",
                "unit": "台",
                "itemIndex": 461
            },
            {
                "title": "櫻花10公升屋外抗風熱水器GH1021(基本安裝)",
                "unit": "台",
                "itemIndex": 462
            },
            {
                "title": "櫻花12公升屋外抗風熱水器GH1221(基本安裝)",
                "unit": "台",
                "itemIndex": 463
            }
        ]
    },
    {
        "type": "林內 (2022.7更新)",
        "item": [
            {
                "title": "林內蓮花爐RB-26GF(B) 停產",
                "unit": "台",
                "itemIndex": 464
            },
            {
                "title": "林內蓮花爐RB-37F 白鐵",
                "unit": "台",
                "itemIndex": 465
            },
            {
                "title": "林內蓮花爐RB-27F 白鐵停產",
                "unit": "台",
                "itemIndex": 466
            },
            {
                "title": "林內蓮花爐RB-F219G(P)晶亮粉玻璃 LED旋鈕 停產",
                "unit": "台",
                "itemIndex": 467
            },
            {
                "title": "林內蓮花爐RB-F219G(CW)純潔白玻離 LED旋鈕 停產",
                "unit": "台",
                "itemIndex": 468
            },
            {
                "title": "林內蓮花爐RB-F219G(G)星空銀玻璃 LED旋鈕 停產",
                "unit": "台",
                "itemIndex": 469
            },
            {
                "title": "林內瓦斯爐RB-S2630G(B)黑玻璃 定時",
                "unit": "台",
                "itemIndex": 470
            },
            {
                "title": "林內防乾燒瓦斯爐RB-Q230G(B)",
                "unit": "台",
                "itemIndex": 471
            },
            {
                "title": "林內三口瓦斯爐RB-302GH(B)",
                "unit": "台",
                "itemIndex": 472
            },
            {
                "title": "林內抽油煙機RH-9126",
                "unit": "台",
                "itemIndex": 473
            },
            {
                "title": "林內瓦斯爐RB-H201S 雙口白鐵",
                "unit": "台",
                "itemIndex": 474
            },
            {
                "title": "林內瓦斯爐RB-202GH(B) 雙口黑玻璃",
                "unit": "台",
                "itemIndex": 475
            },
            {
                "title": "林內倒T式抽油煙機RH-9129",
                "unit": "台",
                "itemIndex": 476
            },
            {
                "title": "林內隱藏式直流變頻抽油煙機RH9628 90公分",
                "unit": "台",
                "itemIndex": 477
            },
            {
                "title": "林內嵌門式落地式烘碗機RKD-6035(S) ",
                "unit": "台",
                "itemIndex": 478
            },
            {
                "title": "林內落地式烘碗機RKD-6053(P) 雙抽臭氧",
                "unit": "台",
                "itemIndex": 479
            },
            {
                "title": "林內落地式烘碗機RKD-6032(S) 雙層黑玻",
                "unit": "台",
                "itemIndex": 480
            },
            {
                "title": "林內落地式烘碗機RKD-6030S 單門黑玻",
                "unit": "台",
                "itemIndex": 481
            },
            {
                "title": "林內落地式烘碗機RKD-5053(P) 50公分雙抽屜",
                "unit": "台",
                "itemIndex": 482
            },
            {
                "title": "林內落地式烘碗機RKD-4553(P) 45公分雙抽屜",
                "unit": "台",
                "itemIndex": 483
            },
            {
                "title": "林內吊掛式烘碗機RKD-196S 90公分(黑/銀)",
                "unit": "台",
                "itemIndex": 484
            },
            {
                "title": "林內吊掛式烘碗機RKD-190UVL 90公分 UV殺菌",
                "unit": "台",
                "itemIndex": 485
            },
            {
                "title": "林內吊掛式烘碗機RKD-390SW 90公分 內白鐵",
                "unit": "台",
                "itemIndex": 486
            },
            {
                "title": "林內吊掛式烘碗機RKD-390(W)",
                "unit": "台",
                "itemIndex": 487
            },
            {
                "title": "林內近吸式抽油煙機RH-9670",
                "unit": "台",
                "itemIndex": 488
            },
            {
                "title": "林內隱藏式抽油煙機RH-9370",
                "unit": "台",
                "itemIndex": 489
            },
            {
                "title": "林內隱藏式抽油煙機RH-9628",
                "unit": "台",
                "itemIndex": 490
            },
            {
                "title": "林內隱藏式抽油煙機RH-9079E",
                "unit": "台",
                "itemIndex": 491
            },
            {
                "title": "林內隱藏式抽油煙機RH-8127 80公分",
                "unit": "台",
                "itemIndex": 492
            },
            {
                "title": "林內隱藏式抽油煙機RH-8126E 80公分",
                "unit": "台",
                "itemIndex": 493
            },
            {
                "title": "林內極炎爐RB-M2700G(B)",
                "unit": "台",
                "itemIndex": 494
            },
            {
                "title": "林內極炎爐RB-M2620G(B) LED+定時",
                "unit": "台",
                "itemIndex": 495
            },
            {
                "title": "林內雙導流板RH-9320(需另購鋁風管)90公分",
                "unit": "台",
                "itemIndex": 496
            },
            {
                "title": "林內極炎爐RB-M2600G(B)",
                "unit": "台",
                "itemIndex": 497
            },
            {
                "title": "林內歐化倒T抽油煙機RH-9029H Turbo鍵 ",
                "unit": "台",
                "itemIndex": 498
            },
            {
                "title": "林內近吸式排油煙機RH-9870 含罩",
                "unit": "台",
                "itemIndex": 499
            },
            {
                "title": "林內倒T式高質感不銹鋼排油煙機RH-9131",
                "unit": "台",
                "itemIndex": 500
            },
            {
                "title": "林內倒T抽油煙機RH-9191 4D直流變頻 升降導流板",
                "unit": "台",
                "itemIndex": 501
            },
            {
                "title": "林內倒T抽油煙機RH-9391R 4D直吸排油煙機(連動)",
                "unit": "台",
                "itemIndex": 502
            },
            {
                "title": "林內倒T抽油煙機RH-9390 4D直吸導流 按鈕式",
                "unit": "台",
                "itemIndex": 503
            },
            {
                "title": "林內抽油煙機RH-9170E 隱藏式排油煙機",
                "unit": "台",
                "itemIndex": 504
            },
            {
                "title": "林內倒T抽油煙機RH-9171 倒T式4D直吸排油煙機",
                "unit": "台",
                "itemIndex": 505
            },
            {
                "title": "林內倒T抽油煙機RH-9621 倒T式全直流變頻排油煙機",
                "unit": "台",
                "itemIndex": 506
            },
            {
                "title": "林內倒T抽油煙機RH-9128 倒T式導流板排油煙機",
                "unit": "台",
                "itemIndex": 507
            },
            {
                "title": "林內倒T抽油煙機RH-9131(B) 黑",
                "unit": "台",
                "itemIndex": 508
            },
            {
                "title": "林內頂側雙吸排油煙機RH-L9700D(白鐵機罩另購)",
                "unit": "台",
                "itemIndex": 509
            },
            {
                "title": "林內RH-L9700D用白鐵機罩",
                "unit": "組",
                "itemIndex": 510
            },
            {
                "title": "林內隱藏式抽油煙機RH-9127",
                "unit": "台",
                "itemIndex": 511
            },
            {
                "title": "林內隱藏式抽油煙機RH-9126E 白鐵電熱除油",
                "unit": "台",
                "itemIndex": 512
            },
            {
                "title": "林內隱藏式抽油煙機RH-7176S 70公分",
                "unit": "台",
                "itemIndex": 513
            },
            {
                "title": "林內倒T抽油煙機RH-9029H",
                "unit": "台",
                "itemIndex": 514
            },
            {
                "title": "林內斜背式抽油煙機RH-9176S",
                "unit": "台",
                "itemIndex": 515
            },
            {
                "title": "林內斜背式抽油煙機RH-8025A 電熱除油 80公分 ",
                "unit": "台",
                "itemIndex": 516
            },
            {
                "title": "林內均分三口內焰爐RB-N312G(B) LED",
                "unit": "台",
                "itemIndex": 517
            },
            {
                "title": "林內防漏雙口RB-N202GH(BW)",
                "unit": "台",
                "itemIndex": 518
            },
            {
                "title": "林內內焰爐RB-N212G(B) LED",
                "unit": "台",
                "itemIndex": 519
            },
            {
                "title": "林內內焰爐RB-N2620G(B)LED",
                "unit": "台",
                "itemIndex": 520
            },
            {
                "title": "林內內焰爐RB-201GNB",
                "unit": "台",
                "itemIndex": 521
            },
            {
                "title": "林內白鐵內焰爐RB-N201S",
                "unit": "台",
                "itemIndex": 522
            },
            {
                "title": "林內雙口不鏽鋼內焰爐RB-N201S",
                "unit": "台",
                "itemIndex": 523
            },
            {
                "title": "林內蓮花爐RB-F212G(B)  LED旋鈕",
                "unit": "台",
                "itemIndex": 524
            },
            {
                "title": "林內IH爐RB-2232H智慧感應二口爐",
                "unit": "台",
                "itemIndex": 525
            },
            {
                "title": "林內瓦斯爐RB-S2630G(B) 黑玻 定時",
                "unit": "台",
                "itemIndex": 526
            },
            {
                "title": "林內隱藏式抽油煙機RH-8079E",
                "unit": "台",
                "itemIndex": 527
            },
            {
                "title": "林內三口爐連烤(銀)RB71W23L7R5-STW-TR",
                "unit": "台",
                "itemIndex": 528
            },
            {
                "title": "林內爐連烤內焰玻璃三口爐 RBG-N71W5GA3X-SVL-TR",
                "unit": "台",
                "itemIndex": 529
            },
            {
                "title": "林內防漏爐連烤RB71AM5U32R-VW-TR",
                "unit": "台",
                "itemIndex": 530
            },
            {
                "title": "林內IH智慧感應二口爐(微晶玻璃)RB-H2280A",
                "unit": "台",
                "itemIndex": 531
            },
            {
                "title": "林內IH智慧感應直向二口爐(微晶玻璃)RB-H2180",
                "unit": "台",
                "itemIndex": 532
            },
            {
                "title": "林內IH智慧感應三口爐(微晶玻璃)RB-H3280",
                "unit": "台",
                "itemIndex": 533
            },
            {
                "title": "林內彩焱爐RB-L3700S(R右大/L左大) 三口不銹鋼",
                "unit": "台",
                "itemIndex": 534
            },
            {
                "title": "林內彩焱爐RB-L3710GB(R右大/L左大) 三口黑玻",
                "unit": "台",
                "itemIndex": 535
            },
            {
                "title": "林內單口IH爐RB-H1180 小尺寸",
                "unit": "台",
                "itemIndex": 536
            },
            {
                "title": "林內單口IH爐RB-H1181S 小尺寸",
                "unit": "台",
                "itemIndex": 537
            },
            {
                "title": "林內單口IH爐RB-H2180",
                "unit": "台",
                "itemIndex": 538
            },
            {
                "title": "林內單口爐RB-N100G(B)",
                "unit": "台",
                "itemIndex": 539
            },
            {
                "title": "林內IH單口爐RB-1132H",
                "unit": "台",
                "itemIndex": 540
            },
            {
                "title": "林內極焰爐RB-M2720G(B) 黑玻 定時 LED",
                "unit": "台",
                "itemIndex": 541
            },
            {
                "title": "林內彩焱爐RB-L2710G(B) 黑玻 LED 大面板",
                "unit": "台",
                "itemIndex": 542
            },
            {
                "title": "林內彩焱爐RB-L2700S 白鐵",
                "unit": "台",
                "itemIndex": 543
            },
            {
                "title": "林內彩焱爐RB-L2600G(B) 黑玻",
                "unit": "台",
                "itemIndex": 544
            },
            {
                "title": "林內彩焱爐RB-L2610G(B) 黑玻 LED",
                "unit": "台",
                "itemIndex": 545
            },
            {
                "title": "林內彩焱爐RB-L2620G(B) 黑玻 LED 定時",
                "unit": "台",
                "itemIndex": 546
            },
            {
                "title": "林內彩焱爐RB-L2600S 白鐵",
                "unit": "台",
                "itemIndex": 547
            },
            {
                "title": "林內緻溫兩口爐RB-A2660G(B) 定時定溫",
                "unit": "台",
                "itemIndex": 548
            },
            {
                "title": "林內緻溫兩口爐RB-A2660S 白鐵 定時定溫",
                "unit": "台",
                "itemIndex": 549
            },
            {
                "title": "林內緻溫玻璃三口爐RB-A3760G(B)定時定溫 (左/右大) ",
                "unit": "台",
                "itemIndex": 550
            },
            {
                "title": "林內檯面式感溫玻璃雙口爐RB-Q230G(B)",
                "unit": "台",
                "itemIndex": 551
            },
            {
                "title": "林內雙口內焰爐RB-N218G(B) LED黑玻 連動",
                "unit": "台",
                "itemIndex": 552
            },
            {
                "title": "林內瓦斯爐RB-H201S 白鐵",
                "unit": "台",
                "itemIndex": 553
            },
            {
                "title": "林內電器收納櫃RVD-6010",
                "unit": "台",
                "itemIndex": 554
            },
            {
                "title": "林內16L強制排氣熱水器RUA-1600WF 含裝 煙管另計",
                "unit": "台",
                "itemIndex": 555
            },
            {
                "title": "林內強制排氣熱水器RUA-1200WF 含基本安裝",
                "unit": "台",
                "itemIndex": 556
            }
        ]
    },
    {
        "type": "喜特麗(2022.12.27更新)",
        "item": [
            {
                "title": "喜特麗落地式烘碗機JT-3056Q 50公分",
                "unit": "台",
                "itemIndex": 557
            },
            {
                "title": "喜特麗落地式烘碗機JT-3166Q 白",
                "unit": "台",
                "itemIndex": 558
            },
            {
                "title": "喜特麗落地式烘碗機JT-3066Q LCD面板",
                "unit": "台",
                "itemIndex": 559
            },
            {
                "title": "喜特麗吊掛烘碗機JT-3819QB 鏡面 黑",
                "unit": "台",
                "itemIndex": 560
            },
            {
                "title": "喜特麗吊掛烘碗機JT-3888QUV",
                "unit": "台",
                "itemIndex": 561
            },
            {
                "title": "喜特麗吊掛式烘碗機JT3690QW 電子鐘/臭氧/白鐵筷架",
                "unit": "台",
                "itemIndex": 562
            },
            {
                "title": "喜特麗吊掛式烘碗機JT3619Q",
                "unit": "台",
                "itemIndex": 563
            },
            {
                "title": "喜特麗吊掛式烘碗機JT3618QW",
                "unit": "台",
                "itemIndex": 564
            },
            {
                "title": "喜特麗吊掛式烘碗機JT-3760QW 60公分",
                "unit": "台",
                "itemIndex": 565
            },
            {
                "title": "喜特麗吊掛式烘碗機JT-3791Q",
                "unit": "台",
                "itemIndex": 566
            },
            {
                "title": "喜特麗隱藏式抽油煙機JT-1890 90公分",
                "unit": "台",
                "itemIndex": 567
            },
            {
                "title": "喜特麗雙口玻璃防空燒檯面爐JT-GC209AF 黑玻",
                "unit": "台",
                "itemIndex": 568
            },
            {
                "title": "喜特麗雙口玻璃檯面爐JT-GC209A",
                "unit": "台",
                "itemIndex": 569
            },
            {
                "title": "喜特麗雙口玻璃檯面爐JT-GC209AW 白玻",
                "unit": "台",
                "itemIndex": 570
            },
            {
                "title": "喜特麗雙口玻璃檯面爐JT-2009A",
                "unit": "台",
                "itemIndex": 571
            },
            {
                "title": "喜特麗雙口不鏽鋼檯面爐JT-209S 不鏽鋼",
                "unit": "台",
                "itemIndex": 572
            },
            {
                "title": "喜特麗雙口不銹鋼檯面爐JT-2009S",
                "unit": "台",
                "itemIndex": 573
            },
            {
                "title": "喜特麗雙口不銹鋼檯面爐JT-GC212S",
                "unit": "台",
                "itemIndex": 574
            },
            {
                "title": "喜特麗隱藏式變頻排油煙機JT-1690",
                "unit": "台",
                "itemIndex": 575
            },
            {
                "title": "喜特麗斜背式抽油煙機 JT-1700",
                "unit": "台",
                "itemIndex": 576
            },
            {
                "title": "喜特麗斜背式抽油煙機 JT-1331L",
                "unit": "台",
                "itemIndex": 577
            },
            {
                "title": "喜特麗斜背式抽油煙機 JT-1331M",
                "unit": "台",
                "itemIndex": 578
            },
            {
                "title": "喜特麗斜背式無油網抽油煙機 JT-1735 新",
                "unit": "台",
                "itemIndex": 579
            },
            {
                "title": "喜特麗斜背式無油網抽油煙機 JT-1732",
                "unit": "台",
                "itemIndex": 580
            },
            {
                "title": "喜特麗全隱藏式電熱除油排油煙機JT-1820M 80公分",
                "unit": "台",
                "itemIndex": 581
            },
            {
                "title": "喜特麗隱藏式變頻排油煙機JT-1859",
                "unit": "台",
                "itemIndex": 582
            },
            {
                "title": "喜特麗隱藏式排油煙機 JT-139S 不鏽鋼",
                "unit": "台",
                "itemIndex": 583
            },
            {
                "title": "喜特麗電器收納櫃JT-7650L",
                "unit": "台",
                "itemIndex": 584
            },
            {
                "title": "喜特麗上掀式電器收納櫃JT-7620L",
                "unit": "台",
                "itemIndex": 585
            },
            {
                "title": "喜特麗倒T型排油煙機JT-1112L 90公分",
                "unit": "台",
                "itemIndex": 586
            },
            {
                "title": "喜特麗雙口IH智能連動微晶調理爐JT-IH239SR",
                "unit": "台",
                "itemIndex": 587
            },
            {
                "title": "喜特麗單口電陶爐JTEG-101(110V/220V)",
                "unit": "台",
                "itemIndex": 588
            },
            {
                "title": "喜特麗倒T排油煙機(智能連動) JT-1168LR",
                "unit": "台",
                "itemIndex": 589
            },
            {
                "title": "喜特麗中繼馬達JT-B003(基本安裝)",
                "unit": "式",
                "itemIndex": 590
            }
        ]
    },
    {
        "type": "豪山(2022.?.??更新)",
        "item": [
            {
                "title": "豪山隱藏式抽油煙機V-901P",
                "unit": "台",
                "itemIndex": 591
            },
            {
                "title": "豪山瓦斯爐SB-2183",
                "unit": "台",
                "itemIndex": 592
            },
            {
                "title": "豪山檯面爐ST-2190",
                "unit": "台",
                "itemIndex": 593
            },
            {
                "title": "豪山檯面爐SB-2200                                                        ",
                "unit": "台",
                "itemIndex": 594
            },
            {
                "title": "豪山雙用微晶調理爐 IR-2339",
                "unit": "台",
                "itemIndex": 595
            },
            {
                "title": "豪山IG 調理爐IG-2390混合爐",
                "unit": "台",
                "itemIndex": 596
            },
            {
                "title": "豪山FW-6880W烘碗機",
                "unit": "台",
                "itemIndex": 597
            },
            {
                "title": "豪山FW-8880W烘碗機",
                "unit": "台",
                "itemIndex": 598
            },
            {
                "title": "豪山FW-9880W烘碗機",
                "unit": "台",
                "itemIndex": 599
            },
            {
                "title": "豪山FW-9882W烘碗機",
                "unit": "台",
                "itemIndex": 600
            },
            {
                "title": "豪山FD-5205落地式烘碗機",
                "unit": "台",
                "itemIndex": 601
            },
            {
                "title": "豪山FD-6215落地式烘碗機",
                "unit": "台",
                "itemIndex": 602
            },
            {
                "title": "豪山抽倒T式VTQ9000-06AN",
                "unit": "台",
                "itemIndex": 603
            },
            {
                "title": "豪山抽直吸式油煙機VSQ-8203S",
                "unit": "台",
                "itemIndex": 604
            },
            {
                "title": "豪山隱藏式抽油煙機VEA-9019PH",
                "unit": "台",
                "itemIndex": 605
            },
            {
                "title": "豪山隱藏式抽油煙機VEQ-8158PN",
                "unit": "台",
                "itemIndex": 606
            },
            {
                "title": "豪山傳統型抽油煙機VSQ-9137S",
                "unit": "台",
                "itemIndex": 607
            },
            {
                "title": "豪山瓦斯爐SB-3205",
                "unit": "台",
                "itemIndex": 608
            },
            {
                "title": "豪山三口均分歐化檯面玻璃爐SB-3208",
                "unit": "台",
                "itemIndex": 609
            },
            {
                "title": "豪山檯面爐ST-2173",
                "unit": "台",
                "itemIndex": 610
            },
            {
                "title": "豪山電陶爐SE-1092",
                "unit": "台",
                "itemIndex": 611
            },
            {
                "title": "豪山IH爐SE-1132",
                "unit": "台",
                "itemIndex": 612
            }
        ]
    },
    {
        "type": "莊頭北",
        "item": [
            {
                "title": "莊頭北抽油煙機TR-5366",
                "unit": "台",
                "itemIndex": 613
            },
            {
                "title": "莊頭北隱藏式不鏽鋼抽油煙機TR-5697",
                "unit": "台",
                "itemIndex": 614
            },
            {
                "title": "莊頭北全隱藏式不鏽鋼抽油煙機TR-5696",
                "unit": "台",
                "itemIndex": 615
            },
            {
                "title": "莊頭北吊掛式烘碗機TG-3205G",
                "unit": "台",
                "itemIndex": 616
            },
            {
                "title": "莊頭北吊掛式烘碗機TG-3206G",
                "unit": "台",
                "itemIndex": 617
            },
            {
                "title": "莊頭北落地烘碗機TD-3662/L",
                "unit": "台",
                "itemIndex": 618
            }
        ]
    },
    {
        "type": "BOSCH(2022.?.??更新) 85",
        "item": [
            {
                "title": "洗/烘碗機門片(半580*594/全695*594) 同門版材質",
                "unit": "片",
                "itemIndex": 619
            },
            {
                "title": "BOSCH沸石獨立洗碗機SMS8ZCIOOX含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 620
            },
            {
                "title": "BOSCH沸石洗碗機SMS88TIOOX 停產",
                "unit": "台",
                "itemIndex": 621
            },
            {
                "title": "BOSCH沸石獨立洗碗機SMS88TIO1W含教學+裝 兩年保 停",
                "unit": "台",
                "itemIndex": 622
            },
            {
                "title": "BOSCH沸石全嵌洗碗機SMV8ZCXOOX含教學+裝兩年保 按壓門",
                "unit": "台",
                "itemIndex": 623
            },
            {
                "title": "BOSCH沸石半嵌洗碗機SMI8ZCSOOX含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 624
            },
            {
                "title": "BOSCH沸石全嵌洗碗機SMV6ZAXOOX含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 625
            },
            {
                "title": "BOSCH獨立式洗碗機SMS88MI01X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 626
            },
            {
                "title": "BOSCH獨立式洗碗機SMS6HAWOOX含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 627
            },
            {
                "title": "BOSCH獨立式洗碗機SMS6HAW10X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 628
            },
            {
                "title": "BOSCH獨立式洗碗機SMS4HAWOOX含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 629
            },
            {
                "title": "BOSCH獨立式洗碗機SMS2ITI06X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 630
            },
            {
                "title": "BOSCH半嵌式洗碗機SMI6HAS00X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 631
            },
            {
                "title": "BOSCH半嵌式洗碗機SMI4HAS00X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 632
            },
            {
                "title": "BOSCH半嵌式洗碗機SMI2ITW00X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 633
            },
            {
                "title": "BOSCH全嵌式洗碗機SMV4HAX00X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 634
            },
            {
                "title": "BOSCH全嵌式洗碗機SMV2ITX00X含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 635
            },
            {
                "title": "BOSCH全嵌式洗碗機SMV6ECX51E 220V含裝兩年保",
                "unit": "台",
                "itemIndex": 636
            },
            {
                "title": "BOSCH全嵌洗碗機SMH4ECX21E 220V含教學+裝 兩年保",
                "unit": "台",
                "itemIndex": 637
            },
            {
                "title": "BOSCH獨立式洗碗機SPS4IMW00X 45公分 含裝兩年保",
                "unit": "台",
                "itemIndex": 638
            },
            {
                "title": "BOSCH獨立式洗碗機SPS2IKI06X 45公分 含裝兩年保",
                "unit": "台",
                "itemIndex": 639
            },
            {
                "title": "BOSCH全嵌式洗碗機SPV4IMX00X 45公分 含裝兩年保",
                "unit": "台",
                "itemIndex": 640
            },
            {
                "title": "BOSCH全嵌式洗碗機SPV2IKX00X 45公分 含裝兩年保",
                "unit": "台",
                "itemIndex": 641
            },
            {
                "title": "BOSCH特殊式洗碗機SCE52M75EU 含裝兩年保",
                "unit": "台",
                "itemIndex": 642
            },
            {
                "title": "BOSCH全嵌式洗碗機自動開門SMH4ECX21E含裝兩年保",
                "unit": "台",
                "itemIndex": 643
            },
            {
                "title": "BOSCH微波蒸烤爐CPA565GS1N 含裝+教學 兩年保",
                "unit": "台",
                "itemIndex": 644
            },
            {
                "title": "BOSCH微波燒烤爐BEL554MS0U 含裝+教學 兩年保",
                "unit": "台",
                "itemIndex": 645
            },
            {
                "title": "BOSCH雙口感應盧PPI82560TW 兩年保",
                "unit": "台",
                "itemIndex": 646
            },
            {
                "title": "BOSCH壁掛式排油煙機 90 cm 碳黑色 DWF97CA20W",
                "unit": "台",
                "itemIndex": 647
            },
            {
                "title": "BOSCH PXY875+DWF9G 2024/6/28前",
                "unit": "式",
                "itemIndex": 648
            },
            {
                "title": "BOSCH PXY875+DIB98 2024/6/28前",
                "unit": "式",
                "itemIndex": 649
            }
        ]
    },
    {
        "type": "AMICA 8折",
        "item": [
            {
                "title": "AMICA全嵌式洗碗機XIV-889T 220V免切踢腳含教學+裝",
                "unit": "台",
                "itemIndex": 650
            },
            {
                "title": "AMICA全嵌式洗碗機ZIV-689T 220V 含教學+裝",
                "unit": "台",
                "itemIndex": 651
            },
            {
                "title": "AMICA全嵌式洗碗機ZIV-665T 220V 含教學+裝",
                "unit": "台",
                "itemIndex": 652
            },
            {
                "title": "AMICA全嵌式洗碗機ZIV-645T(45公分) 含教學+裝",
                "unit": "台",
                "itemIndex": 653
            },
            {
                "title": "AMICA TES 18MX 9種烘烤行程旋風烤箱",
                "unit": "台",
                "itemIndex": 654
            },
            {
                "title": "AMICA全蒸舒肥烤箱XTVIS-1800IX TW 含教學+裝",
                "unit": "台",
                "itemIndex": 655
            },
            {
                "title": "AMICA全蒸舒肥烤箱XTCS-1200IX TW 含教學+裝",
                "unit": "台",
                "itemIndex": 656
            },
            {
                "title": "AMICA大雙口IH感應爐HIP-72B2(左/右大) 含教學+裝",
                "unit": "台",
                "itemIndex": 657
            },
            {
                "title": "AMICA大雙口IH感應爐HIP-72B2 S2 含教學+裝",
                "unit": "台",
                "itemIndex": 658
            },
            {
                "title": "AMICA多口可變式IH感應爐Q4-60MB7400 TW",
                "unit": "台",
                "itemIndex": 659
            },
            {
                "title": "AMICA三口IH爐PI-6530 ATPO 6700W/31A 負載注意",
                "unit": "台",
                "itemIndex": 660
            },
            {
                "title": "AMICA直向雙口IH爐PI-3512TF 含教學+裝",
                "unit": "台",
                "itemIndex": 661
            },
            {
                "title": "AMICA微蒸氣烘焙烤箱XTN-1100IX TW",
                "unit": "台",
                "itemIndex": 662
            },
            {
                "title": "AMICA多工烘焙烤箱QTCI-2000B TW ",
                "unit": "台",
                "itemIndex": 663
            }
        ]
    },
    {
        "type": "其他機器",
        "item": [
            {
                "title": "愛惠浦HS288T PLUS(黑/銀)廚下飲水機+Trio-4H²",
                "unit": "台",
                "itemIndex": 664
            },
            {
                "title": "Puretron雙溫定溫廚下加熱機(銀)+賀眾五道式RO",
                "unit": "台",
                "itemIndex": 665
            },
            {
                "title": "Puretron雙溫定溫廚下加熱機(銀)+賀眾直出水式RO",
                "unit": "台",
                "itemIndex": 666
            },
            {
                "title": "Puretron三溫定溫廚下加熱機(銀)+賀眾五道式RO",
                "unit": "台",
                "itemIndex": 667
            },
            {
                "title": "Puretron三溫定溫廚下加熱機(銀)+賀眾直出水式RO",
                "unit": "台",
                "itemIndex": 668
            },
            {
                "title": "賀眾UW-2212HW-1廚下飲水機+UR-5401JW-1 停產",
                "unit": "台",
                "itemIndex": 669
            },
            {
                "title": "賀眾UW-2202HW-1廚下飲水機(非觸控)+UR-5401JW-1",
                "unit": "台",
                "itemIndex": 670
            },
            {
                "title": "賀眾UW-2202HW-1廚下飲水機(非觸控)+直出水式RO",
                "unit": "台",
                "itemIndex": 671
            },
            {
                "title": "賀眾UR-5401JW-1五道純水淨化",
                "unit": "組",
                "itemIndex": 672
            },
            {
                "title": "賀眾無桶式ROUR-5902JW-1 送(前兩道+檢修)*1 保1.5年",
                "unit": "台",
                "itemIndex": 673
            },
            {
                "title": "愛惠浦PurVive Trio-4C²過濾器",
                "unit": "台",
                "itemIndex": 674
            },
            {
                "title": "愛惠浦PurVive Trio-4H²過濾器",
                "unit": "台",
                "itemIndex": 675
            },
            {
                "title": "賀眾廚下型加熱器UW-2202 一般龍頭+RO UR-5401",
                "unit": "台",
                "itemIndex": 676
            },
            {
                "title": "ASKO嵌入式洗碗機DBI644MIB.W.TW/1 白門",
                "unit": "台",
                "itemIndex": 677
            },
            {
                "title": "ASKO嵌入式洗碗機DBI644MIB.S.TW/1 不鏽鋼門",
                "unit": "台",
                "itemIndex": 678
            },
            {
                "title": "ASKO全嵌式洗碗機DFI433B.TW/1 週年慶特價",
                "unit": "台",
                "itemIndex": 679
            },
            {
                "title": "ASKO全嵌式洗碗機DFI433B.TW/1",
                "unit": "台",
                "itemIndex": 680
            },
            {
                "title": "ASKO嵌入式洗碗機DBI243IB.W.TW 白門 週年慶特價",
                "unit": "台",
                "itemIndex": 681
            },
            {
                "title": "ASKO嵌入式洗碗機DBI243IB.W.TW 白門",
                "unit": "台",
                "itemIndex": 682
            },
            {
                "title": "smeg ST65221K 洗碗機",
                "unit": "台",
                "itemIndex": 683
            },
            {
                "title": "Miele半嵌式洗碗機G7114C-SCi ",
                "unit": "台",
                "itemIndex": 684
            },
            {
                "title": "Miele洗碗機G7101C SCVi 220V",
                "unit": "台",
                "itemIndex": 685
            },
            {
                "title": "Miele半嵌式洗碗機G7104C SCVi 220V",
                "unit": "台",
                "itemIndex": 686
            },
            {
                "title": "Miele全嵌式洗碗機G5264C-SCVi 220V",
                "unit": "台",
                "itemIndex": 687
            },
            {
                "title": "Miele半嵌式洗碗機G5214C-SCi 220V",
                "unit": "台",
                "itemIndex": 688
            },
            {
                "title": "Miele洗碗機G7364C SCVi 220V",
                "unit": "台",
                "itemIndex": 689
            },
            {
                "title": "Miele獨立式洗碗機G7101C-SC 110V 含裝+教學",
                "unit": "台",
                "itemIndex": 690
            },
            {
                "title": "國際牌IH爐 KY-E227E-(W白/K黑) 代理 含裝",
                "unit": "台",
                "itemIndex": 691
            },
            {
                "title": "國際牌IH爐 KY-C227E(灰) 代理 含裝",
                "unit": "台",
                "itemIndex": 692
            },
            {
                "title": "國際牌日本原裝進口升降櫃F07090 90公分",
                "unit": "台",
                "itemIndex": 693
            },
            {
                "title": "國際牌電動升降烘碗櫃F07290 90公分",
                "unit": "台",
                "itemIndex": 694
            },
            {
                "title": "Panasonic嵌入式自動洗碗烘乾機 PULL OPEN抽屜式",
                "unit": "台",
                "itemIndex": 695
            },
            {
                "title": "Panasonic洗碗機NP-2KTBGR1TW 220V 不含玻璃門",
                "unit": "台",
                "itemIndex": 696
            },
            {
                "title": "SVAGO PLANA SV IS 90中島式排油煙機",
                "unit": "台",
                "itemIndex": 697
            },
            {
                "title": "SVAGO TID3580橫式雙口感應爐",
                "unit": "台",
                "itemIndex": 698
            },
            {
                "title": "SVAGO單口感應爐TID2310 110V/1500W",
                "unit": "台",
                "itemIndex": 699
            },
            {
                "title": "SVAGO VE7650 半嵌式自動開門洗碗機 110V",
                "unit": "台",
                "itemIndex": 700
            },
            {
                "title": "SVAGO VE7750全嵌式自動開門洗碗機 110V",
                "unit": "台",
                "itemIndex": 701
            },
            {
                "title": "TAKA IZF-68700 60公分全區感應爐 要散熱",
                "unit": "台",
                "itemIndex": 702
            },
            {
                "title": "TAKA STEAKMASTER SMR廚神700",
                "unit": "台",
                "itemIndex": 703
            },
            {
                "title": "SVAGO VE8960 嵌入式蒸烤箱",
                "unit": "台",
                "itemIndex": 704
            },
            {
                "title": "Electrolux 伊萊克斯 EHI7260BA 雙口感應爐",
                "unit": "台",
                "itemIndex": 705
            },
            {
                "title": "伊萊克斯半嵌式洗碗機 EEM48300IX 原廠二年保",
                "unit": "台",
                "itemIndex": 706
            },
            {
                "title": "伊萊克斯全嵌式洗碗機 EEEM9420L原廠二年保",
                "unit": "台",
                "itemIndex": 707
            },
            {
                "title": "菲雪品克Fisher&Paykel 抽屜式洗碗機 DD60SHI9",
                "unit": "台",
                "itemIndex": 708
            },
            {
                "title": "油煙機中繼馬達 管路另計",
                "unit": "組",
                "itemIndex": 709
            },
            {
                "title": "PACIFIC變頻倒T排油煙機PGT-M90 玻璃觸控",
                "unit": "台",
                "itemIndex": 710
            },
            {
                "title": "PACIFIC中島式倒T排油煙機PRM-M90",
                "unit": "台",
                "itemIndex": 711
            },
            {
                "title": "Electrolux60公分700系列半嵌式洗碗機EEM48300IX",
                "unit": "台",
                "itemIndex": 712
            },
            {
                "title": "BlueSkyT型分壓排油煙機BS-9609A(90cm)",
                "unit": "台",
                "itemIndex": 713
            },
            {
                "title": "BlueSky全隱藏可掀式排油煙機90cm",
                "unit": "台",
                "itemIndex": 714
            },
            {
                "title": "BlueSky90CMT型巴弗排油煙機BS-9509B",
                "unit": "台",
                "itemIndex": 715
            },
            {
                "title": "BlueSky極靜分離式排油煙機 雙主機BS-9609AS2 90cm",
                "unit": "台",
                "itemIndex": 716
            },
            {
                "title": "BlueSky極靜分離式排油煙機 單主機BS-9609AS 90cm",
                "unit": "台",
                "itemIndex": 717
            },
            {
                "title": "BlueSky炊飯器收納櫃BS-1015B52(經典黑)",
                "unit": "台",
                "itemIndex": 718
            },
            {
                "title": "BlueSky炊飯器收納櫃BS-1015B60T1R(經典黑/含托盤)",
                "unit": "台",
                "itemIndex": 719
            },
            {
                "title": "分離式排油煙機安裝工資",
                "unit": "台",
                "itemIndex": 720
            },
            {
                "title": "best電器收納櫃G-931703",
                "unit": "台",
                "itemIndex": 721
            }
        ]
    },
    {
        "type": "系統櫃",
        "item": [
            {
                "title": "系統高櫃(E1板，H240、D60內)",
                "unit": "公分",
                "itemIndex": 722
            },
            {
                "title": "系統矮櫃(E1板，H120、D60內)",
                "unit": "公分",
                "itemIndex": 723
            },
            {
                "title": "系統疊櫃(E1板，含上封板H120、D60內)",
                "unit": "公分",
                "itemIndex": 724
            },
            {
                "title": "系統吊櫃(E1板，H120、D37內)",
                "unit": "公分",
                "itemIndex": 725
            },
            {
                "title": "系統吊櫃(E1板，H120、D60內)",
                "unit": "公分",
                "itemIndex": 726
            },
            {
                "title": "系統高櫃(E0板，H240、D60內)",
                "unit": "公分",
                "itemIndex": 727
            },
            {
                "title": "系統矮櫃(E0板，H120、D60內)",
                "unit": "公分",
                "itemIndex": 728
            },
            {
                "title": "系統矮櫃(E1板，H120、D60內)+六面T6 TEMPO門板",
                "unit": "公分",
                "itemIndex": 729
            },
            {
                "title": "EGGER系統高櫃(E0板，H240、D60內)",
                "unit": "公分",
                "itemIndex": 730
            },
            {
                "title": "EGGER系統矮櫃(E0板，H120、D60內)",
                "unit": "公分",
                "itemIndex": 731
            },
            {
                "title": "下櫃EGGER系統板+EGGER門板",
                "unit": "公分",
                "itemIndex": 732
            },
            {
                "title": "吊櫃EGGER系統板+EGGER門板",
                "unit": "公分",
                "itemIndex": 733
            },
            {
                "title": "系統板檯面(E1板、厚2.5公分、深60內)",
                "unit": "公分",
                "itemIndex": 734
            },
            {
                "title": "系統板檯面(E0板、厚2.5公分、深60內)",
                "unit": "公分",
                "itemIndex": 735
            },
            {
                "title": "EGGER系統板檯面(E0、厚2.5公分)",
                "unit": "公分",
                "itemIndex": 736
            },
            {
                "title": "系統板背板(E1板，H120內)",
                "unit": "公分",
                "itemIndex": 737
            },
            {
                "title": "系統床頭板(E1板，H120內、D60內)",
                "unit": "公分",
                "itemIndex": 738
            },
            {
                "title": "系統床櫃(E1板，標準雙人床底152*188 H35)",
                "unit": "式",
                "itemIndex": 739
            },
            {
                "title": "系統床櫃(E1板，標準單人床底106*188 H35)",
                "unit": "式",
                "itemIndex": 740
            },
            {
                "title": "橫推門軌道",
                "unit": "式",
                "itemIndex": 741
            },
            {
                "title": "橫推門門外包框(含加深至65公分)",
                "unit": "式",
                "itemIndex": 742
            },
            {
                "title": "橫推門R型鋁條",
                "unit": "片",
                "itemIndex": 743
            },
            {
                "title": "鋁框玻璃推拉門",
                "unit": "片",
                "itemIndex": 744
            },
            {
                "title": "系統門板斜把手",
                "unit": "公分",
                "itemIndex": 745
            },
            {
                "title": "緩衝式木抽(E1板抽框)",
                "unit": "組",
                "itemIndex": 746
            },
            {
                "title": "掛衣桿",
                "unit": "只",
                "itemIndex": 747
            },
            {
                "title": "重型拍拍手",
                "unit": "只",
                "itemIndex": 748
            },
            {
                "title": "緩衝式木抽(E0框)",
                "unit": "組",
                "itemIndex": 749
            },
            {
                "title": "台製緩衝式格子抽",
                "unit": "組",
                "itemIndex": 750
            },
            {
                "title": "Blum隱藏式緩衝木抽",
                "unit": "組",
                "itemIndex": 751
            },
            {
                "title": "台製緩衝式托盤(E1)",
                "unit": "組",
                "itemIndex": 752
            },
            {
                "title": "格柵板(雙片板黏合 240*60內)",
                "unit": "式",
                "itemIndex": 753
            },
            {
                "title": "洞洞板",
                "unit": "式",
                "itemIndex": 754
            },
            {
                "title": "旋轉穿衣鏡",
                "unit": "式",
                "itemIndex": 755
            },
            {
                "title": "衣櫃LED燈 黃/自然/白 光",
                "unit": "公分",
                "itemIndex": 756
            },
            {
                "title": "門控LED+變壓器+櫃內走線+燈條嵌入安裝",
                "unit": "式",
                "itemIndex": 757
            },
            {
                "title": "系統牆釘層板(E1板，厚2.5公分，深25公分)",
                "unit": "公分",
                "itemIndex": 758
            },
            {
                "title": "門板Z把手",
                "unit": "片",
                "itemIndex": 759
            },
            {
                "title": "門板J把手",
                "unit": "公分",
                "itemIndex": 760
            },
            {
                "title": "門板斜把手",
                "unit": "公分",
                "itemIndex": 761
            },
            {
                "title": "抽屜U把手",
                "unit": "式",
                "itemIndex": 762
            },
            {
                "title": "木框玻璃門 清玻璃(非強化)",
                "unit": "片",
                "itemIndex": 763
            },
            {
                "title": "大鋁框玻璃門 黑框 清玻璃(非強化 40*240內)",
                "unit": "片",
                "itemIndex": 764
            },
            {
                "title": "國際牌旋轉鞋架",
                "unit": "組",
                "itemIndex": 765
            },
            {
                "title": "鏡子(灰) 長240內 寬45內",
                "unit": "片",
                "itemIndex": 766
            },
            {
                "title": "系統TV櫃(E1板，、D60內)",
                "unit": "公分",
                "itemIndex": 767
            },
            {
                "title": "電視櫃下玻璃門 清玻璃(非強化) 含繳練",
                "unit": "片",
                "itemIndex": 768
            },
            {
                "title": "系統櫃報價含緩衝鉸鏈、層板、一般把手、踢腳板",
                "unit": "式",
                "itemIndex": 769
            },
            {
                "title": "系統櫃特殊把手、玻璃/橫推門、抽屜等其他配件另計",
                "unit": "式",
                "itemIndex": 770
            },
            {
                "title": "系統櫃鐵架、插座、電燈另計",
                "unit": "式",
                "itemIndex": 771
            },
            {
                "title": "基本運送安裝工資(非外包)+搬運0樓(計費方式見附件)",
                "unit": "式",
                "itemIndex": 772
            }
        ]
    },
    {
        "type": "浴櫃",
        "item": [
            {
                "title": "浴櫃發泡板桶身+六面結晶鋼烤門板 含白鐵緩衝",
                "unit": "公分",
                "itemIndex": 773
            },
            {
                "title": "發泡板鏡櫃+六面結晶鋼烤門板",
                "unit": "公分",
                "itemIndex": 774
            },
            {
                "title": "石英石檯面 S系列",
                "unit": "公分",
                "itemIndex": 775
            },
            {
                "title": "石英石水槽下嵌*1工資(非平接)",
                "unit": "式",
                "itemIndex": 776
            },
            {
                "title": "LG人造石檯面(含止水線、背牆4公分) 深60內",
                "unit": "公分",
                "itemIndex": 777
            },
            {
                "title": "人造石面盆上裝開孔工資(非下嵌)",
                "unit": "式",
                "itemIndex": 778
            },
            {
                "title": "門上方切斜把手",
                "unit": "公分",
                "itemIndex": 779
            },
            {
                "title": "一般把手(黑/鋁)",
                "unit": "式",
                "itemIndex": 780
            },
            {
                "title": "開放層架",
                "unit": "式",
                "itemIndex": 781
            },
            {
                "title": "造型腳",
                "unit": "支",
                "itemIndex": 782
            },
            {
                "title": "不鏽鋼三角架",
                "unit": "只",
                "itemIndex": 783
            },
            {
                "title": "鏡子",
                "unit": "片",
                "itemIndex": 784
            },
            {
                "title": "浴櫃安裝工資",
                "unit": "式",
                "itemIndex": 785
            },
            {
                "title": "浴櫃發泡板凹型抽屜",
                "unit": "組",
                "itemIndex": 786
            },
            {
                "title": "可拆塑膠防水踢腳板(黑/鋁色)",
                "unit": "式",
                "itemIndex": 787
            },
            {
                "title": "B800型實心人造石洗衣槽",
                "unit": "組",
                "itemIndex": 788
            },
            {
                "title": "人造石水槽下嵌工資*1(非平接) 蓋頓",
                "unit": "組",
                "itemIndex": 789
            },
            {
                "title": "TOTO面盆LW523GUR 寬43公分",
                "unit": "組",
                "itemIndex": 790
            },
            {
                "title": "TOTO面盆用單槍龍頭TLS01308PD(長) 附按壓排水零件",
                "unit": "組",
                "itemIndex": 791
            }
        ]
    },
    {
        "type": "其他工程",
        "item": [
            {
                "title": "浴室拆運+防水+貼磚(牆30*60,$80、地30*30,$45)",
                "unit": "式",
                "itemIndex": 792
            },
            {
                "title": "原水泥灶拆除+貼地磚(40*40磚)、不含牆壁",
                "unit": "式",
                "itemIndex": 793
            },
            {
                "title": "原水泥灶拆除+拆除部分水泥抹平(不含貼磚、水電)",
                "unit": "式",
                "itemIndex": 794
            },
            {
                "title": "磁磚剃除僅表面 若須拆除至見磚費用另計",
                "unit": "式",
                "itemIndex": 795
            },
            {
                "title": "天花板拆裝 PS板(半實心) 原角料沿用",
                "unit": "式",
                "itemIndex": 796
            },
            {
                "title": "天花板拆裝 矽酸鈣板平釘(油漆補土另計) 原角料沿用",
                "unit": "式",
                "itemIndex": 797
            },
            {
                "title": "天花板LED燈(白/黃/自然光) 含裝",
                "unit": "式",
                "itemIndex": 798
            },
            {
                "title": "怡心牌ES-309電熱水器 110V 洗碗用",
                "unit": "台",
                "itemIndex": 799
            },
            {
                "title": "DAY&DAY毛巾桿45公分(ST2045+2600-2*1)",
                "unit": "式",
                "itemIndex": 800
            },
            {
                "title": "DAY&DAY毛巾桿60公分(ST2060+2600-2*1)",
                "unit": "式",
                "itemIndex": 801
            },
            {
                "title": "DAY&DAY毛巾桿90公分(ST2090+2600-2*1)",
                "unit": "式",
                "itemIndex": 802
            },
            {
                "title": "DAY&DAY毛巾桿120公分(ST2120+2600-2*1+2600M*1)",
                "unit": "式",
                "itemIndex": 803
            },
            {
                "title": "DAY&DAY毛巾桿150公分(ST2150+2600-2*1+2600M*1)",
                "unit": "式",
                "itemIndex": 804
            },
            {
                "title": "DAY&DAY毛巾桿180公分(ST2180+2600-2*1+2600M*1)",
                "unit": "式",
                "itemIndex": 805
            },
            {
                "title": "DAY&DAY鎖吊櫃毛巾桿120公分(ST2120,2900-2,2900)",
                "unit": "式",
                "itemIndex": 806
            },
            {
                "title": "DAY&DAY掛勾ST3001-3(三個)",
                "unit": "組",
                "itemIndex": 807
            },
            {
                "title": "DAY&DAY鍋蓋架-掛式-附集水盒ST3027B",
                "unit": "組",
                "itemIndex": 808
            },
            {
                "title": "ALASKA 阿拉斯加 換氣扇 小風地258 含裝",
                "unit": "式",
                "itemIndex": 809
            },
            {
                "title": "強化玻璃淋浴拉門 (一字三門含門檻)",
                "unit": "式",
                "itemIndex": 810
            },
            {
                "title": "觸控可調光LED燈(櫃內走線非嵌入) 黃/自然/白 光 公分",
                "unit": "式",
                "itemIndex": 811
            },
            {
                "title": "感應LED燈(櫃內走線非嵌入) 黃/自然/白 光 公分",
                "unit": "式",
                "itemIndex": 812
            },
            {
                "title": "淋浴拉門 一字三門 5mm透明強化清玻 含門檻",
                "unit": "式",
                "itemIndex": 813
            },
            {
                "title": "淋浴拉門 一字三門 PS板 不含門檻",
                "unit": "式",
                "itemIndex": 814
            },
            {
                "title": "淋浴拉門門檻 含安裝",
                "unit": "式",
                "itemIndex": 815
            },
            {
                "title": "壁式冷熱水管鑿溝下移(不含貼磚)(不含電源)",
                "unit": "式",
                "itemIndex": 816
            },
            {
                "title": "塑膠門+塑膠門框+喇叭鎖 含裝",
                "unit": "式",
                "itemIndex": 817
            },
            {
                "title": "全戶式手動過濾器活性碳+石英砂",
                "unit": "組",
                "itemIndex": 818
            },
            {
                "title": "馬桶、臉盆、水電(拉電及改管路)、浴室配件另計",
                "unit": "式",
                "itemIndex": 819
            },
            {
                "title": "JAS不鏽鋼烤灰電熱毛巾架 ET106002(左出線) 含裝",
                "unit": "式",
                "itemIndex": 820
            }
        ]
    }
]