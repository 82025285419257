import { menuData } from "../global/OrderProduct"
import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation
} from "react-router-dom";

import lineImg from '../assets/line.png'
import fbImg from '../assets/fb.png'

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';



const Footer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation()

  const changeLanguage = () => {
    console.log(i18n.language)
    if (i18n.language == 'zh-TW') {
      i18n.changeLanguage('en');
    }
    else {
      i18n.changeLanguage('zh-TW');
    }
  };

  const [showStoryMenu, setShowStoryMenu] = useState(false)

  function saveScrollId(scrollId) {
    console.log('sss')
    localStorage.setItem('scrollId', scrollId)

    scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

    console.log(localStorage.getItem('scrollId'))
  }


  const menulist = [
    {
      title: "首頁",
      path: "/"
    },
    {
      title: "安裝實例",
      path: "/product"
    },
    // {
    //   title: "取得報價",
    //   path: "/order"
    // },
    {
      title: "關於我們",
      path: "/aboutus"
    },

    {
      title: "聯絡我們",
      path: "/contact"
    },
  ]



  return <div className="w-full">
    <div className="appWidth  mt-20 py-10 bg-white ">
      <List
        sx={{ py: 0, width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >

        {

          menulist.map(i =>
            <ListItemButton sx={{ px: 0 }} onClick={() => navigate(i.path)} >
              <ListItemText primary={i.title} primaryTypographyProps={
                {
                  textAlign: 'center',
                  color: '#12171E',
                  fontWeight: 600,
                }} />
            </ListItemButton>
          )}

      </List>
    </div>



    <div className="w-full text-white bg-black92">
      <div className=" mx-auto justify-center lg:justify-between flex  flex-wrap  gap-y-[60px] gap-x-[100px] w-full px-[20px] lg:px-[100px] py-[100px]  ">


        <div className="w-max ">
          <p className="text-center md:text-left font-bold text-2xl mb-5">營業時間</p>
          <div className="flex flex-col text-sm gap-2 items-center md:items-start ">
            <p>平日: 9:00~18:00(12:00~13:30午休)</p>
            <p>六日: 14:00~17:00</p>
            <p>營業時間以GOOGLE地圖為準</p>

          </div>


        </div>

        <div className="w-max ">
          <p className="text-center md:text-left font-bold text-2xl mb-5">門市地址</p>
          <div className="flex flex-col text-sm gap-2 items-center md:items-start ">
            <p className="w-[230px]">地址: 302 台灣新竹市新竹縣竹北市白地街276巷8-3號 (大樓對面巷子)</p>
     
          </div>

        </div>


        <div className=" w-max ">


          <p className="text-center md:text-left font-bold text-2xl mb-5">聯絡方式</p>
          <div className="flex flex-col text-sm gap-2  items-center md:items-start ">
            <p>市話: 03-5555881</p>
            <p>陳先生: 0978-316-369</p>
            <p>莊小姐: 0989-316-289</p>
            <p>LineID: @greenstar</p>


          </div>
        </div>

        <div className={`flex justify-between items-center text-white bg-black92 relative py-[50px]  mx-auto w-full`} style={{ "borderTop": "1px solid rgba(93, 99, 102, 0.4)" }}>


          <div>
            <p>綠星能源科技有限公司</p>
            <p>Copyright © {new Date().getFullYear()} All Rights Reserved.</p>
          </div>

          <div className="flex items-center">
            <a href="https://page.line.me/352gxyil?oat__id=2338131&openQrModal=true" target="_blank">
              <img className="w-8 h-8 object-contain mr-4" src={lineImg} />
            </a>
            <a href="https://www.facebook.com/GreenStarTw" target="_blank">
              <img className="w-8 h-8 object-contain " src={fbImg} />
            </a>
          </div>
        </div>

      </div>
    </div>









  </div>

}
export default Footer