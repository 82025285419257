
const initState = {

  userInfo: null,
  loading: false,

};

const itemReducer = (state = initState, action) => {
  switch (action.type) {

    case 'UPDATE_USERINFO': {
      return { ...state, userInfo: action.payload.userInfo };
    }
    case 'UPDATE_LOADING': {
      return { ...state, loading: action.payload.loading };
    }

    default:
      return state;
  }
};


export { itemReducer };