import { menuData } from "../global/OrderProduct"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useSearchParams
} from "react-router-dom";

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



import menuImg from '../assets/menu.png';
import logoImg from '../assets/logo.png';

import closeImg from '../assets/closebk.png';


import searchwlImg from '../assets/searchwl.png';


import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';




const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



function MenuDialog(props) {
    const { onClose, menulist, open } = props;
    //const theme = useTheme();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate()

    const [selMenuIndex, setSelMenuIndex] = useState(0)

    const [currPage, setCurrPage] = useState(0)

    const [checkboxStatus, setCheckboxStatus] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('768'));

    const handleClose = () => {

        onClose("");
    };

    const handleListItemClick = (path) => {
        navigate(path)
        onClose("");
    };

    const [openList1, setOpenList1] = useState(false);
    const [openList2, setOpenList2] = useState(false);
    const [openList3, setOpenList3] = useState(false);

    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh-TW');
        }
    };
    function openCollapse(value) {
        if (value == 1) {
            setOpenList1(!openList1)
            setOpenList2(false)
            setOpenList3(false)
        }
        else if (value == 2) {
            setOpenList1(false)
            setOpenList2(!openList2)
            setOpenList3(false)
        }
        else if (value == 3) {
            setOpenList1(false)
            setOpenList2(false)
            setOpenList3(!openList3)
        }

    }


    const handleClick = () => {
        //setOpenList(!openList);
    };

    return (
        <Dialog maxWidth="2000" PaperProps={{ sx: { bgcolor: "transparent" } }} fullScreen={true} open={open} >
            <div className="w-full h-full relative" onClick={handleClose} >

            </div>

            <div className=" absolute bg-white w-[250px] h-screen px-10 py-10 right-0 top-0 "  >

                <List
                    sx={{py: 0, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >

                    {

                        menulist.map(i =>
                            <ListItemButton sx={{ px: 0 }} onClick={() => handleListItemClick(i.path)} >
                                <ListItemText primary={i.title} primaryTypographyProps={
                                    {
                                        color: '#12171E',
                                        fontWeight: 600,
                                    }} />
                            </ListItemButton>
                        )}



                </List>
                <div className=" w-full h-[2px] bg-[#5D6366] bg-opacity-[0.08] my-10"></div>

                {/* <div className="flex items-center cursor-pointer" onClick={() => changeLanguage()}>
                    <img className="relative object-contain w-6 h-6 " src={globalImg} />
                    <p className="ml-[10px] mb-[0px] font-semibold">{i18n.language == 'zh-TW' ? "中文" : "En"}</p>

                </div> */}

            </div>





        </Dialog>
    );
}

const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const navigate = useNavigate()

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showSearchBar, setShowSearchBar] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [openMenu, setOpenMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    const handleClickOpen = () => {
        setOpenMenu(true);
    };

    const handleClickClose = (value) => {
        setOpenMenu(false);
        setSelectedValue(value);
    };


    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh-TW');
        }

    };

    const [selMenuIndex, setSelMenuIndex] = useState(0)

    const [showStoryMenu, setShowStoryMenu] = useState(false)
    const [showBackBtn, setShowBackBtn] = useState(false)

    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }

    const menulist = [
        {
            title: "首頁",
            path: "/"
        },
        {
            title: "安裝實例",
            path: "/product"
        },
        // {
        //     title: "取得報價",
        //     path: "/order"
        // },
        {
            title: "關於我們",
            path: "/aboutus"
        },
        // {
        //     title: "常見問題",
        //     path: "/"
        // },
        {
            title: "聯絡我們",
            path: "/contact"
        },
        {
            title: "管理員專區",
            path: "/order"
        },
    ]



    const [searchToken, setSearchToken] = useState("")
    const [searchResult, setSearchResult] = useState([])

    const [searchInput, setSearchInput] = useState("")



    function filterSearchToken(inputToken) {

        setShowSearchBar(false)
        navigate(`/search?token=${inputToken}`)


    }

    useEffect(() => {

    }, []);




    return <div className="relative z-10   w-full">

        

        <div className="h-[66px] lg:h-[81px]" id="header"></div>
        {/* <div className="hidden fixed bottom-0 right-0 z-10  ">
            <img className="w-[94px] object-contain cursor-pointer " src={scrollTopImg} onClick={() => saveScrollId('header')} />
        </div> */}
        <div></div>
        <div style={{ "borderBottom": "1px solid rgba(93, 99, 102, 0.2)" }} className="fixed top-0 left-0 w-full flex justify-between items-center h-[66px] lg:h-[81px] bg-white text-black px-5 xl:px-[100px]">

            <img onClick={() => navigate('/')} className={`${showSearchBar ? "hidden lg:block" : ""} cursor-pointer h-[60px] object-contain `} src={logoImg} />

            {/* {
                showSearchBar ? <>
                    <div className="w-full max-w-[600px] flex gap-[10px] p-[3px] h-[54px] border-solid border-[#DADCDD] border-[3px] rounded">
                        <input className=" bg-transparent w-full text-h4  text-main2 px-4" type="text" placeholder={t('search.sec1.t1')} onChange={(e) => setSearchInput(e.target.value)} />
                        <button onClick={() => filterSearchToken(searchInput)} className="bg-main h-full w-[64px] flex-none rounded">
                            <img className="w-6 h-6 m-auto" src={searchwlImg} />
                        </button>

                    </div>

                    <div onClick={() => setShowSearchBar(false)} className="ml-5 flex-none w-[54px] h-[54px] flex bg-[#5D6366] bg-opacity-[0.08] rounded-full">
                        <img className="w- h-6 m-auto" src={closeImg} />
                    </div>
                </>
                    : ""
            } */}
            {
                !showSearchBar ? <>
                    <div className="hidden lg:flex  justify-center">
                        {
                            menulist.map((i, index) =>
                                <Link to={i.path}
                                    className={`${location.pathname == i.path?"selTitle":""} animTitle flex items-center cursor-pointer font-semibold  text-h5 mx-5`}
                                >
                                    {i.title}
                                </Link>

                            )}

                    </div>

                    <div className="w-[60px]"></div>
                    <img onClick={handleClickOpen} className="lg:hidden relative object-contain w-6 h-6  cursor-pointer" src={menuImg} />
                    {/* <div className="flex items-center">
                        <img onClick={() => setShowSearchBar(true)} className="relative object-contain w-6 h-6 mr-[30px] flex-none cursor-pointer" src={searchImg} />
                        <img onClick={handleClickOpen} className="lg:hidden relative object-contain w-6 h-6  cursor-pointer" src={menuImg} />
                        <div className="hidden lg:flex w-[66px]  items-center font-semibold text-h4 cursor-pointer" onClick={() => changeLanguage()}>
                            <img className="relative object-contain w-6 h-6 mr-3" src={globalImg} />
                            <p className="flex-none">{i18n.language == 'zh-TW' ? "中文" : "En"}</p>

                        </div>
                    </div> */}
                </>
                    : ""
            }


        </div>



        <MenuDialog
            menulist={menulist}
            open={openMenu}
            onClose={handleClickClose}
        />

    </div>

}
export default Header