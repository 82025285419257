
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';

import Marquee from "react-fast-marquee";



import quoteLeftImg from '../../assets/quoteleft.png'
import quoteRightImg from '../../assets/quoteright.png'
import arrowImg from '../../assets/arrow.png'




import pic1Img from '../../assets/productImg/greenstarimg28.png'
import pic2Img from '../../assets/productImg/greenstarimg98.jpg'
import pic3Img from '../../assets/productImg/greenstarimg11.png'
import pic4Img from '../../assets/productImg/greenstarimg81.png'


import linear1Img from '../../assets/productImg/greenstarimg26.jpg'
import linear2Img from '../../assets/productImg/greenstarimg27.jpg'
import linear3Img from '../../assets/productImg/greenstarimg28.png'
import linear4Img from '../../assets/productImg/greenstarimg29.jpg'
import linear5Img from '../../assets/productImg/greenstarimg30.jpg'

import ushape1Img from '../../assets/productImg/greenstarimg93.jpg'
import ushape2Img from '../../assets/productImg/greenstarimg94.jpg'
import ushape3Img from '../../assets/productImg/greenstarimg95.jpg'
import ushape4Img from '../../assets/productImg/greenstarimg96.jpg'
import ushape5Img from '../../assets/productImg/greenstarimg97.jpg'

import lshape1Img from '../../assets/productImg/greenstarimg7.jpg'
import lshape2Img from '../../assets/productImg/greenstarimg8.jpg'
import lshape3Img from '../../assets/productImg/greenstarimg9.png'
import lshape4Img from '../../assets/productImg/greenstarimg10.jpg'
import lshape5Img from '../../assets/productImg/greenstarimg11.png'

import systemcabinet1Img from '../../assets/productImg/greenstarimg66.jpg'
import systemcabinet2Img from '../../assets/productImg/greenstarimg67.jpg'
import systemcabinet3Img from '../../assets/productImg/greenstarimg68.jpg'
import systemcabinet4Img from '../../assets/productImg/greenstarimg69.jpg'
import systemcabinet5Img from '../../assets/productImg/greenstarimg60.jpg'



import Swiper from '../../compontents/Swiper'
import CommentSwiper from '../../compontents/CommentSwiper'

import Button from '../../compontents/Button'


import { Link } from "react-router-dom";


import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
  useSearchParams

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Dialog from '@mui/material/Dialog';


import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';



const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();



  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClickOpen = (filename) => {
    setSelectedImage(filename);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [selTypeIndex, setSelTypeIndex] = useState(0);


  const serviceStepList = [
    "拿尺寸至門市/線上(FB、LINE)免費設計規劃估價",
    "細項報價，簽約保障雙方權益",
    "現場複量，提供圖面雙方來回確認",
    "按圖施工，細項報價，價格透明"
  ]

  const commentList = [
    {
      link: "https://g.co/kgs/teYtWH",
      name: "Yu-Hao Huang",
      star: 5,
      content: "老闆和老闆娘很親切幽默，也會給我們設計上許多實用的建議，櫥櫃價格也很透明，cp值很高，施工中以及很多後續收尾師傅也都很細心，還幫我們解決了陽台沒有洗滌槽的困擾，希望好店家可以永續經營下去",
    },
    {
      link: "https://g.co/kgs/mkYbrD",
      name: "Kevin Chang",
      star: 5,
      content: `第一次自己設計統包自家老宅，找了好幾家系統櫃跟廚具，有些店家是只接單（實際安裝是分包），綠星有自己的師傅工班，品質有保障。

老闆跟老闆娘好溝通也會儘量達成業主的需求，尺寸與規劃上相當專業，價格合理透明。小老闆也很專業，在板材顏色搭配也都給予很好的建議跟案例圖，同樣板材在不同燈光下確實有明顯不同的成色。 如果現場有更大塊的板材就更能想像完成的樣子。

安裝的阿傑跟小董師傅專業細心，在施作現場都會跟業主確認，細節處理與收邊也都很棒，值的推👍🏻`,
    },
    {
      link: "https://g.co/kgs/c27onF",
      name: "Lee Albert",
      star: 5,
      content: `不用到處比了，這裡真的C/P值最好！
家裡的廚具跟系統櫃以及衛浴全部都找綠星，除了公司有點偏僻跟訂單現在有點滿要等比較久之外，不論是產品品質，施工品質，性價比等都非常滿意。加上老闆以及板娘經驗豐富，可以讓選擇障礙的你/妳挑出最適合,實用且好看的產品唷！`,
    },
    {
      link: "https://g.co/kgs/7jTQcT",
      name: "Penny Hsu",
      star: 5,
      content: `老闆跟老闆娘非常熱心，除了專業知識分享還會幫忙考量預算提供櫥櫃跟檯面的最佳組合。安裝團隊也是非常有效率，師傅人也是熱心客氣。整個case處理下來其實已經超過五星的服務了!!`,
    },
    {
      link: "https://g.co/kgs/rg24m2",
      name: "HAI HAI",
      star: 5,
      content: `值得推薦的好店家，絕對會推薦給需要的朋友`,
    },
    {
      link: "https://g.co/kgs/AzmahP",
      name: "鄭麗華",
      star: 5,
      content: "老闆夫婦具溫暖的親和力，專業經營歷史已超過十幾年，耐心聽取與配合顧客需求，用心規劃，細心分析各種材質的品質與價格間的關係，但又不會給予抉擇時的壓力，老板同時是樹木的愛好者與木雕藝術的創作者，值得推薦。",
    }
  ]


  const menulist = [
    {
      title: "首頁",
      path: "/"
    },
    {
      title: "安裝實例",
      path: "/product"
    },
    {
      title: "取得報價",
      path: "/order"
    },
    {
      title: "關於我們",
      path: "/aboutus"
    },
    // {
    //     title: "常見問題",
    //     path: "/"
    // },
    {
      title: "聯絡我們",
      path: "/contact"
    },
  ]



  const subServiceList = [
    {
      img: "",
      title: t('home.sec4.t8'),
      link: "/service/commerce"
    },
    {
      img: "",
      title: t('home.sec4.t9'),
      link: "/contact/askanexpert"
    },
    {
      img: "",
      title: t('home.sec4.t10'),
      link: "/service/supplychain"
    },
  ]



  const productType = [
    {
      img: pic1Img,
      title: "一字型廚具",
      link: "",
      imgs: [
        linear1Img,
        linear2Img,
        linear3Img,
        linear4Img,
        linear5Img,
      ]
    },
    {
      img: pic2Img,
      title: "ㄇ字型廚具",
      link: "",
      imgs: [
        ushape1Img,
        ushape2Img,
        ushape3Img,
        ushape4Img,
        ushape5Img,
      ]
    },
    {
      img: pic3Img,
      title: "L字型廚具",
      link: "",
      imgs: [
        lshape1Img,
        lshape2Img,
        lshape3Img,
        lshape4Img,
        lshape5Img,
      ]
    },
    {
      img: pic4Img,
      title: "系統櫃",
      link: "",
      imgs: [
        systemcabinet1Img,
        systemcabinet2Img,
        systemcabinet3Img,
        systemcabinet4Img,
        systemcabinet5Img,
      ]
    },
  ]


  const handleScroll = event => {
    //console.log('scrollTop: ', event.currentTarget.scrollTop);
    //console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    //console.log('offsetHeight: ', event.currentTarget.scrollHeight);



  };





  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideShowInterval = setInterval(() => {
      // 遞增 currentSlide 並在超過最後一張時重置為 0
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 5);
    }, 3000);

    // 在組件卸載時清除 setInterval
    return () => clearInterval(slideShowInterval);
  }, []);






  useEffect(() => {


  }, []);

  return <div className=" overflow-hidden py-10"   >

    <div className="px-[20px] appWidth flex lg:flex-row flex-col lg:items-center gap-10 mb-8">
      <div className="relative  rounded-xl w-full lg:w-1/2 xl:w-2/3 aspect-[712/506] object-cover">
        {productType[selTypeIndex].imgs.map((i, index) => (
          <div
            key={index}
            className={` rounded-[20px] overflow-hidden slide shadow-md ${index === currentSlide ? 'active' : ''}`}
          >
            <img className="w-full h-full object-cover pointer-events-none " src={i} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      {/* <img src={bannerImg} className="hidden rounded-xl w-full lg:w-1/2 xl:w-2/3 aspect-[712/506] object-cover" /> */}
      <div className="text-black91 flex-none leading-relaxed">
        <h1 className="text-6xl font-semibold mb-2">綠星廚具</h1>
        <div class="visibleAnim text-4xl sm:text-6xl font-semibold mb-6 text-main2 ">
          <ul>
            <li>廚房設計規劃 </li>
            <li>系統櫃設計規劃 </li>
            <li>廚房設計規劃</li>
            <li>系統櫃設計規劃</li>
          </ul>
        </div>

        <p className=" text-black9">
          手工打造<br />
          量身定製新竹在地服務二十年以上驗工廠直營<br />
          線上/來店 免費估價
        </p>
      </div>

    </div>

    <Swiper list={productType} selTypeIndex={setSelTypeIndex} />

    {/* <div>
      <Marquee gradient={false} pauseOnHover={true} direction="right">
        {
          productType.map(i => <div key={i} onClick={() => handleClickOpen(i.img)} className=" cursor-pointer relative mr-4 sm:mr-12 my-6 p-4  shadow-[2px_2px_10px_rgba(0,0,0,0.08)] bg-white border-2 border-solid hover:border-main border-transparent rounded-lg flex  items-center">
            <div className="w-60 rounded-lg aspect-[4/3] overflow-hidden">
              <img src={i.img} className="w-full h-full object-cover " />
            </div>

            <div className="pl-4 pr-2 text-main font-semibold text-xl flex-none">
              {i.title}
            </div>
          </div>)
        }

      </Marquee>
    </div> */}

    <div className="appWidth lg:px-[20px] mt-40  mb-8">
      <p className="text-5xl font-semibold mb-20 text-center">關於綠星</p>

      <div className=" flex gap-10">
        <div className="hidden lg:block flex-none text-main  pt-16">
          <img className=" object-contain w-[40px] bg" src={quoteLeftImg} />
        </div>
        <div className="w-full text-black91 bg-white text-center px-[20px] py-[50px] lg:p-20 rounded-lg shadow-[2px_2px_10px_rgba(0,0,0,0.05)]">
          <div className="flex">
            <div class="mx-auto  visibleAnim text-6xl font-semibold mb-3 text-main">
              <ul>
                <li className="text-center">廚具系統</li>
                <li className="text-center">室內設計</li>
                <li className="text-center">熱水系統</li>
                <li className="text-center">廚具系統</li>
              </ul>
            </div>
          </div>
          <p className=" text-5xl font-semibold">在地服務超過二十年的故事</p>
          <div className="h-0.5 bg-main w-full my-10">
          </div>
          <p className="text-sm sm:text-base whitespace-pre-line">{`綠星廚具是位於新竹的廚具製造商
            主要服務範圍為桃園、新竹、苗栗
            擁有工廠自行生產，能依不同廚房製造出適合的櫥櫃
            從挑選材質、報價、設計、組裝、到安裝一次服務到位
            加上在地經營超過二十年，有著豐富的設計規畫經驗
            能依照客戶的需求提供建議，打造出屬於自己的廚房`}</p>
        </div>
        <div className="hidden lg:block flex-none  text-main pt-16">
          <img className=" object-contain w-[40px]" src={quoteRightImg} />
        </div>
      </div>


    </div>

    {/* <div className="appWidth  mt-40  mb-8">
      <p className="text-5xl font-semibold mb-20 text-center">服務項目</p>

      <div className="grid px-[20px] lg:px-[100px] grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px]">
        {
          serviceList.map(i =>
            <div key={i.title} className="  bg-white rounded w-full p-[30px] sm:p-[50px]">
              <img className="mx-auto relative object-contain w-[100px] h-[100px] mb-[24px]" src={i.img} />


              <p className="text-black9 font-bold mb-2 text-h2.5 text-center">{i.title}</p>

              <p className="text-black9 text-h4 text-center">{i.content}</p>

            </div>
          )
        }
        <div className=" rounded w-full  flex mt-8 md:mt-0">
          <div className="m-auto" id="cusBtn">
            <button onClick={()=>navigate('/product')} className="cusBtn learn-more text-h3 text-black9 font-bold">
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">瀏覽安裝實例</span>
            </button>
          </div>
        </div>
      </div>


    </div> */}

    <div className="relative appWidth  mt-40  mb-8 px-[20px]">
      <p className="text-5xl font-semibold mb-20 text-center">服務流程</p>
      <div className="relative w-full ">
        <div className="shadow-md bg-white w-full lg:w-2/3 rounded-lg border-2 border-solid border-main p-[30px] sm:p-[50px]">
          {
            serviceStepList.map((i, index) => <div key={i} className="flex items-center text-black91 text-h5 font-bold my-6">
              {
                Array(index + 1).fill(0).map(i => <img className="relative object-contain w-[10px] h-[10px] mr-3" src={arrowImg} />)
              }

              {i}
            </div>
            )
          }

        </div>

        <div className="shadow-md mt-6 lg:mt-0 w-full lg:w-2/5 lg:absolute right-0 top-1/2  lg:-translate-y-1/2 rounded-lg bg-main p-[30px] sm:p-[50px]">

          <div className="text-white text-h5 ">
            <p className="mb-4 font-bold ">全品項最低保固一年，終身服務</p>
            <p className="mb-0.5">本工廠已免費包含廚房規劃</p>
            <p className="mb-0.5">亦可配合設計師、統包等規畫格局、配色</p>
            <p className="mb-0.5">廠商如需拜訪以上面方式聯絡即可</p>
          </div>

        </div>

      </div>

    </div>

    <div className="appWidth  mt-40  mb-8">
      <p className="text-5xl font-semibold mb-20 text-center">客戶感言</p>
      <div className="mb-[50px]">
        <CommentSwiper serviceList={commentList}></CommentSwiper>
      </div>

    </div>

    {/* <div className="appWidth  mt-40 ">
      <List
        sx={{ py: 0, width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >

        {

          menulist.map(i =>
            <ListItemButton sx={{ px: 0 }} onClick={() => navigate(i.path)} >
              <ListItemText primary={i.title} primaryTypographyProps={
                {
                  textAlign:'center',
                  color: '#12171E',
                  fontWeight: 600,
                }} />
            </ListItemButton>
          )}

      </List>
    </div> */}



    {/* <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="1440px"
    >

      {
        open ?
          <div className="p-[20px] md:p-[50px] w-[1028px] max-w-full">
            <video id="video" className="w-full  object-contain" autoPlay muted loop playsInline rel="preload">
              <source src={video1} type="video/mp4" data-wf-ignore="true" rel="preload" />
            </video>



          </div>
          : ""
      }



    </Dialog> */}


    {
      selectedImage != '' ?
        <Dialog open={open} onClose={handleClose}>
          <img src={selectedImage} alt={''} style={{ maxWidth: '100%', maxHeight: '80vh' }} />
        </Dialog>
        : ""
    }

  </div>

}

export default Home