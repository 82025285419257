import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './index.scss';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'

import { Provider } from "react-redux";
import { store } from "./model/store.js";



import Home from "./pages/Home";

import Order from "./pages/Order";
import Product from "./pages/Product";
import ProductDetail from "./pages/ProductDetail";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";


import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";


import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import RouteChange from './compontents/RouteChange'
import './firebase.js';
import LoadingComponent from './compontents/LoadingComponent';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter >
      <div className='flex flex-col justify-between w-full min-h-screen mx-auto  bg-[#FAFAFA]' >
        <ScrollToTop />
        <RouteChange />

        <LoadingComponent />


        <div className="w-full relative">
          <Header />
          <div className='w-full max-w-[1240px] mx-auto'>
            <Routes >

              <Route path="/" element={<Home />}></Route>
              <Route path="/product" element={<Product />}></Route>
              {/* <Route path="/productdetail" element={<ProductDetail />}></Route> */}
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/aboutus" element={<AboutUs />}></Route>
              <Route path="/order" element={<Order />}></Route>


              <Route path='*' element={<Navigate to='/' replace />} />


            </Routes>
          </div>
        </div>

        <Footer />
      </div>

    </BrowserRouter>
  </Provider>




);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
