import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import "./style.css";

import quotecircleImg from '../assets/quotecircle.png'

// import required modules
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";


export default function App(props) {

  const [banner, setBanner] = useState(props.serviceList)
  const navigate = useNavigate()


  useEffect(() => {



  }, []);

  const pagination = {
    "clickable": true,

  }

  return (
    <>
      <Swiper
        //slidesPerView={2.6}
        breakpoints={{
          // when window width is >= 100px
          100: {
            slidesPerView: 1.2,
          },
          // when window width is >= 968px
          968: {
            slidesPerView: 2.2,
          },
          1440: {
            slidesPerView: 3.4,
          },
        }}
        spaceBetween={20}
        centeredSlides={false} 
        grabCursor={true}
        //pagination={pagination}
        // autoplay={true}
        //modules={[Pagination, Autoplay]}
        //modules={[Pagination]}
        className="mySwiper autoplay "
      >
        {
          banner.map((i, index) => <SwiperSlide key={index} className="rounded-md  shadow-md"> <a href={i.link} target="_blank"><div className="bg-white rounded w-full p-[20px] pt-[40px] sm:p-[50px]">
            <img className=" absolute top-0 left-8 transform  -translate-y-1/2 !object-contain !w-12 !h-12 " src={quotecircleImg} />

            <p className="text-left min-h-[200px]">{i.content}</p>
            <div className="flex justify-between items-center flex-wrap gap-1 mt-4 ">
              <p className="text-main font-bold text-h5">- {i.name}</p>
              <div className="rating-holder pointer-events-none overflow-hidden">
                <div className="c-rating c-rating--regular" data-rating-value={String(i.star)}>
                  <button>1</button>
                  <button>2</button>
                  <button>3</button>
                  <button>4</button>
                  <button>5</button>
                </div>
              </div>
            </div>


          </div></a></SwiperSlide>)
        }



      </Swiper>


    </>
  );
}
