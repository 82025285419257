
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';

import Marquee from "react-fast-marquee";



import quoteLeftImg from '../../assets/quoteleft.png'
import quoteRightImg from '../../assets/quoteright.png'
import arrowImg from '../../assets/arrow.png'





import icon1Img from '../../assets/icon/1.png'
import icon2Img from '../../assets/icon/2.png'
import icon3Img from '../../assets/icon/3.png'
import icon4Img from '../../assets/icon/4.png'
import icon5Img from '../../assets/icon/5.png'


import Swiper from '../../compontents/Swiper'
import CommentSwiper from '../../compontents/CommentSwiper'

import Button from '../../compontents/Button'


import { Link } from "react-router-dom";


import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
  useSearchParams

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Dialog from '@mui/material/Dialog';




const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();



  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClickOpen = (filename) => {
    setSelectedImage(filename);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const serviceList = [
    {
      img: icon1Img,
      title: "廚具/系統櫃",
      content: "從經銷到工廠直營，期間規劃過無數廚房，擁有豐富的經驗。",
      link: "/service/commerce"
    },
    {
      img: icon2Img,
      title: "熱水系統",
      content: "明宜太陽能新竹經銷，擁有燃氣熱水器承裝執照、太陽能熱水器證書等，同樣熟悉於廚房管路規劃。",
      link: "/service/supplychain"
    },
    {
      img: icon3Img,
      title: "室內設計",
      content: "擁有室內裝修工程管理證照，走訪過無數工地，承接過多次統包，能配合設計出實用的廚具。",
      link: "/service/supplychain"
    },
    {
      img: icon4Img,
      title: "水電材料批發",
      content: "早期為水電材料批發商，能用合理的價格幫客戶拿到需要的商品。",
      link: "/service/supplychain"
    },
    {
      img: icon5Img,
      title: "餐廳",
      content: "做過餐廳空間規劃且擁有廚師證照，更熟知廚房的需求、動線。",
      link: "/service/supplychain"
    },
  ]

  const handleScroll = event => {
    //console.log('scrollTop: ', event.currentTarget.scrollTop);
    //console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    //console.log('offsetHeight: ', event.currentTarget.scrollHeight);



  };




  useEffect(() => {


  }, []);

  return <div className=" overflow-hidden py-20"   >

    <div className="appWidth   mb-8">
      <h1 className="text-5xl font-semibold mb-20 text-center">服務項目</h1>

      <div className="grid px-[20px] lg:px-[100px] grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px]">
        {
          serviceList.map(i =>
            <div key={i.title} className="  bg-white rounded w-full p-[30px] sm:p-[50px]">
              <img className="mx-auto relative object-contain w-[100px] h-[100px] mb-[24px]" src={i.img} />


              <h2 className="text-black9 font-bold mb-2 text-h2.5 text-center">{i.title}</h2>

              <p className="text-black9 text-h4 text-center">{i.content}</p>

            </div>
          )
        }
        <div className=" rounded w-full  flex mt-8 md:mt-0">
          <div className="m-auto" id="cusBtn">
            <button onClick={()=>navigate('/product')} className="cusBtn learn-more text-h3 text-black9 font-bold">
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">瀏覽安裝實例</span>
            </button>
          </div>
        </div>
      </div>


    </div>

  </div>

}

export default Home