export const ProductFilter = [
    {
      title: "類型",
      open: false,
      sub: [
        {
          title: "廚櫃",
          value: "kitchencabinet",
          sel: false
        },
        {
          title: "系統櫃",
          value: "systemcabinet",
          sel: false
        },
        {
          title: "浴室",
          value: "bathroom",
          sel: false
        },
      ]
    },
    {
      title: "廚櫃格局",
      open: false,
      sub: [
        {
          title: "一字",
          value: "linear",
          sel: false
        },
        {
          title: "L型",
          value: "lshape",
          sel: false
        },
        {
          title: "ㄇ字",
          value: "ushape",
          sel: false
        },
        {
          title: "中島",
          value: "island",
          sel: false
        },
        {
          title: "電器櫃",
          value: "appliancecabinet",
          sel: false
        },
        // {
        //   title: "冰箱櫃",
        //   value: "refrigeratorcabinet",
        //   sel: false
        // },
      ]
    },
    // {
    //   title: "櫥櫃門板顏色",
    //   open: false,
    //   sub: [
    //     {
    //       title: "紅色",
    //       value: "red",
    //       sel: false
    //     },
    //     {
    //       title: "粉紅色",
    //       value: "pink",
    //       sel: false
    //     },
    //     {
    //       title: "橘色",
    //       value: "orange",
    //       sel: false
    //     },
    //     {
    //       title: "自然暖色調(奶茶色、拿鐵色、裸色)",
    //       value: "naturalwarmtones",
    //       sel: false
    //     },
    //     {
    //       title: "黃色",
    //       value: "yellow",
    //       sel: false
    //     },
    //     {
    //       title: "綠色",
    //       value: "green",
    //       sel: false
    //     },
    //     {
    //       title: "藍色",
    //       value: "blue",
    //       sel: false
    //     },
    //     {
    //       title: "紫色",
    //       value: "purple",
    //       sel: false
    //     },
    //     {
    //       title: "白色",
    //       value: "white",
    //       sel: false
    //     },
    //     {
    //       title: "灰色",
    //       value: "gray",
    //       sel: false
    //     },
    //     {
    //       title: "黑色",
    //       value: "black",
    //       sel: false
    //     },
    //   ]
    // },
    {
      title: "門板材質",
      open: false,
      sub: [
        {
          title: "亮面",
          value: "gloss",
          sel: false
        },
        {
          title: "霧面",
          value: "matte",
          sel: false
        },
        {
          title: "鄉村風",
          value: "rustic",
          sel: false
        },
        // {
        //   title: "烤漆",
        //   value: "lacquer",
        //   sel: false
        // },
      ]
    },
    {
      title: "其他標籤",
      open: false,
      sub: [
        // {
        //   title: "倒T抽油煙機",
        //   value: "invertedtexhausthood",
        //   sel: false
        // },
        {
          title: "配色",
          value: "colorcoordination",
          sel: false
        },
        // {
        //   title: "快速爐",
        //   value: "speedoven",
        //   sel: false
        // },
        // {
        //   title: "頂天",
        //   value: "touchceiling",
        //   sel: false
        // },
        {
          title: "玻璃門",
          value: "glassdoor",
          sel: false
        },
        {
          title: "熱門照片",
          value: "popular",
          sel: false
        },
      ]
    },
  ]