import { useLocation ,useNavigate } from 'react-router-dom';
import { useEffect } from "react";

export default function RouteChange() {
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    return
    // runs on location, i.e. route, change
    console.log('handle route change here', location)
    const userID = localStorage.getItem('userID')
    if (userID == undefined){
      if (location.pathname != '/login'){
        navigate("/login")
      }
    }
    else{
      const auth = localStorage.getItem('auth')
      if (auth == "normal"){
        navigate("/joinevent")
      }
      else if (auth == "admin"){
        navigate("/hostevent")
      }

    }

  }, [location])

}